import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';

const CheckValidIcon = () => {
  return (
    <span
      style={{
        fontSize: 18,
        color: '#008566',
      }}
    >
      <FontAwesomeIcon icon={faCheckCircle} />
    </span>
  );
};

export default CheckValidIcon;

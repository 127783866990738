import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import commonReducer from './common';
import stepsReducer from './steps';
import userReducer from './user';
import startStepReducer from './startStep';
import dateAndTimeStepReducer from './dateAndTimeStep';
import finishStepReducer from './finishStep';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    common: commonReducer,
    steps: stepsReducer,
    user: userReducer,
    startStep: startStepReducer,
    dateAndTimeStep: dateAndTimeStepReducer,
    finishStep: finishStepReducer
  });


export default createRootReducer;
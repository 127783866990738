const publicUrl = process.env.PUBLIC_URL;

export const stepRoutes = {
    start: `${publicUrl}/start`,
    dateAndTime: `${publicUrl}/dateAndTime`,
    finish: `${publicUrl}/finish`
}

export const routes = {
    login: `${publicUrl}/login`,
    remoteLogin: `${publicUrl}/remote-login`,
    errorPage: `${publicUrl}/error-page`,
    root: `${publicUrl}/`,
    thankYou: `${publicUrl}/thank-you`,
    cancellation: `${publicUrl}/cancellation`,
    logout: `${publicUrl}/logout`
}

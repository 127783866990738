import classes from './InfoBlocks.module.scss';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { registerDataSelector } from '../../store/selectors/finishStep';
import { userInfoSelector } from '../../store/selectors/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMobileAndroid, faEnvelope } from '@fortawesome/pro-light-svg-icons';
import Text from '../UI/Typography/Text';
import H from '../UI/Typography/H';

const UserInfo = () => {
  const registerData = useSelector(registerDataSelector);
  const userInfo = useSelector(userInfoSelector);

  return (
    <Grid item xs={12} className={classes.User}>
      <Grid item xs={12}>
        <H s={3}>Personuppgifter</H>
      </Grid>
      <Grid item xs={12} className={classes.Info}>
        <div>
          <span>
            <FontAwesomeIcon icon={faUser} />
          </span>
          <Text>
            {userInfo.firstName} {userInfo.secondName}
          </Text>
        </div>
        <div>
          <span>
            <FontAwesomeIcon icon={faMobileAndroid} />
          </span>
          <Text>{registerData.phone.viewValue}</Text>
        </div>
        <div>
          <span>
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
          <Text>{registerData.email.viewValue}</Text>
        </div>
      </Grid>
    </Grid>
  );
};

export default UserInfo;

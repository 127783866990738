import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/actions/common';
import { getIsAcademediaLogin, removeIsAcademediaLogin } from '../../localStorage/localStorage';
const { REACT_APP_ADMIN_LOGIN_PAGE: link, REACT_APP_BASE_URL: publicURL } = process.env;

const Logout = () => {
  const dispatch = useDispatch();
  const encodeUrl = window.btoa(publicURL);

  useEffect(() => {
    dispatch(logout());
    if (getIsAcademediaLogin()) {
      removeIsAcademediaLogin();
      window.location.href = `${link}user/academedia_logout?frontend_url=${encodeUrl}`;
    } else {
      window.location.href = `${link}user/grandid_logout?frontend_url=${encodeUrl}`;
    }
  });
  return null;
};

export default Logout;

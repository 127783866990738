import axios from 'axios';
import * as cookiesManager from '../cookies/cookies';
import { showErrorPage } from '../store/actions/common';
import { setIsLoggedIn } from '../store/actions/user';

const { REACT_APP_BEARER_TOKEN: bearer, REACT_APP_API_BASE_URL: baseURL, REACT_APP_BASE_URL: publicURL } = process.env;

if (!bearer || !baseURL) {
  throw new Error('ENV VARIABLES WERE NOT FOUND!');
}

export function getAxios(dispatch = null) {
  const axiosApi = axios.create({
    baseURL,
    responseType: 'json',
  });

  axiosApi.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${bearer}`;
    const token = cookiesManager.getAuthCookie();
    if (token) {
      config.headers['X-AUTH'] = token;
    } else {
      if (dispatch) {
        dispatch(setIsLoggedIn(false));
      }
    }
    config.params = config.params || {};
    config.params['frontend_url'] = window.btoa(publicURL);
    return config;
  });

  axiosApi.interceptors.response.use(undefined, (error) => {
    if (dispatch) {
      dispatch(showErrorPage());
    }
  });

  return axiosApi;
}

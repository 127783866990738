import {
  ACTION_SET_ERROR,
  ACTION_SET_SHOW_MOBILE_MENU,
  ACTION_SET_THEME,
  ACTION_SET_WELCOME_TEXT,
  ACTION_SET_WELCOME_TEXT_LOADING,
} from '../actions/common';

const initialState = {
  theme: null,
  welcomeText: '',
  isWelcomeTextLoading: false,
  error: false,
  showMobileMenu: false,
};

export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_SET_THEME:
      return {
        ...state,
        theme: action.payload.theme,
      };
    case ACTION_SET_WELCOME_TEXT:
      return {
        ...state,
        welcomeText: action.payload.text,
      };
    case ACTION_SET_WELCOME_TEXT_LOADING:
      return {
        ...state,
        isWelcomeTextLoading: action.payload.loading,
      };
    case ACTION_SET_ERROR:
      return {
        ...state,
        error: action.payload.isError,
      };
    case ACTION_SET_SHOW_MOBILE_MENU:
      return {
        ...state,
        showMobileMenu: action.payload.show,
      };
    default:
      return state;
  }
}

import classes from './InfoBlocks.module.scss';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  selectedInstructorObjectSelector,
  selectedServiceObjectSelector,
} from '../../store/selectors/startStep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import H from '../UI/Typography/H';
import Text from '../UI/Typography/Text';

const InstructorInfo = () => {
  const selectedServiceType = useSelector(selectedServiceObjectSelector);
  const selectedInstructor = useSelector(selectedInstructorObjectSelector);

  return (
    <Grid item xs={12} className={classes.Instructor}>
      <Grid item xs={12}>
        <H s={3}>{selectedServiceType.name}</H>
      </Grid>
      <Grid item xs={12} className={classes.Info}>
        <span>
          <FontAwesomeIcon icon={faUser} />
        </span>
        <Text>
          {selectedInstructor.firstName} {selectedInstructor.lastName}
        </Text>
      </Grid>
    </Grid>
  );
};

export default InstructorInfo;

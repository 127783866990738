export const themes = {
  BASIC: 'basic',
  EXTERNAL: 'external'
}

export const STEP_STATUS = {
  ACTIVE: 'Active',
  PASSED: 'Passed',
  PENDING: 'Pending',
};

export const STEP_CODES = {
  START: 'start',
  DATE_AND_TIME: 'date-and-time',
  FINISH: 'finish',
};

export const ALL_INSTRUCTORS_OBJ = { id: 0, firstName: 'Visa alla', lastName: '' };

export const MEETING_TYPES = {
  telephone: {
    id: 'phone',
    name: 'Telefonsamtal',
    description: 'Telefonsamtal',
  },
  chat: {
    id: 'digital',
    name: 'Digitalt möte',
    description: 'Digitalt möte',
  },
  physical: {
    id: 'physical',
    name: 'Fysiskt möte',
    description: 'Fysiskt möte',
  },
};

export const SESSION_COOKIE_NAME = 'jwtToken';

export const USER_ROLES = {
  STUDENT: 'customer',
  PERSONNEL: 'provider',
};

export const dateFormat = 'YYYY-MM-DD';
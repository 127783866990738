import { ALL_INSTRUCTORS_OBJ } from '../../constants';

export const isServiceLoadingSelector = (state) => state.startStep.isServiceLoading;
export const isInstructorsLoadingSelector = (state) => state.startStep.isInstructorsLoading;
export const serviceTypesSelector = (state) => state.startStep.serviceTypes;
export const selectedServiceSelector = (state) => state.startStep.selectedService;
export const instructorsSelector = (state) => state.startStep.instructors;
export const selectedInstructorSelector = (state) => state.startStep.selectedInstructor;
export const filteredInstructorSelector = (state) => state.startStep.filteredInstructor;

export const selectedServiceObjectSelector = (state) =>
  state.startStep.serviceTypes.find((type) => type.id === state.startStep.selectedService);
export const selectedInstructorObjectSelector = (state) => {
  return state.startStep.selectedInstructor === 0
    ? ALL_INSTRUCTORS_OBJ
    : state.startStep.instructors.find(
        (instructor) => instructor.id === state.startStep.selectedInstructor
      );
};

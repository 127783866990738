import classes from './Header.module.scss';
import { isMobile } from 'react-device-detect';
import LogoutButton from '../../components/LogoutButton/LogoutButton';
import Logo from '../../components/Logo/Logo';
import LoginAdminButton from '../../components/LoginAdminButton/LoginAdminButton';
import MobileMenuSwitch from '../../components/MobileMenuSwitch/MobileMenuSwitch';
import { useSelector } from 'react-redux';
import { userInfoSelector } from '../../store/selectors/user';
import { USER_ROLES } from '../../constants';
import { useThemeStyle, useThemeWaves } from '../../hooks/hooks';
import Lightwave from '../Waves/Lightwave';

const Header = ({ noActions }) => {
  const userInfo = useSelector(userInfoSelector);
  const isAdmin = userInfo.role_slug === USER_ROLES.PERSONNEL;
  const HeaderClass = useThemeStyle(classes, 'Header');
  const showWaves = useThemeWaves();

  const renderActions = () => {
    return isMobile ? (
      <MobileMenuSwitch isAdmin={isAdmin} />
    ) : (
      <div className={classes.Buttons}>
        {isAdmin ? <LoginAdminButton /> : null}
        <LogoutButton />
      </div>
    );
  };

  return (
    <div className={HeaderClass}>
      <header>
        <div className={classes.Logo}>
          <Logo />
        </div>
        {!noActions ? renderActions() : null}
      </header>
      {showWaves ? <Lightwave /> : null}
    </div>
  );
};

export default Header;

import classes from './MobileMenuSwitch.module.scss';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faExchange } from '@fortawesome/pro-light-svg-icons';
import { useThemeStyle } from '../../hooks/hooks';
import { useDispatch } from 'react-redux';
import { setShowMobileMenu } from '../../store/actions/common';

const MobileMenu = ({ isAdmin }) => {
  const MobileMenuSwitchClass = useThemeStyle(classes, 'MobileMenuSwitch');
  const dispatch = useDispatch();

  const showMenu = () => {
    dispatch(setShowMobileMenu(true));
  };

  const cls = [MobileMenuSwitchClass];
  if (isAdmin) {
    cls.push(classes.Admin);
  }

  return (
    <div className={cls.join(' ')}>
      <div onClick={showMenu} className={classes.ButtonGroup}>
        {isAdmin ? (
          <div className={classes.AdminLoginIcon}>
            <FontAwesomeIcon icon={faExchange} />
          </div>
        ) : null}

        <div className={classes.LogoutIcon}>
          <FontAwesomeIcon icon={faSignOut} />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;

import classes from './EmailInput.module.scss';
import { useState, useEffect } from 'react';
import is from 'is_js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';
import CrossInvalidIcon from '../../../UI/Icons/CrossInvalidIcon';
import CheckValidIcon from '../../../UI/Icons/CheckValidIcon';
import { useThemeStyle } from '../../../../hooks/hooks';

const EmailInput = ({ onBlur, defaultValue, reference }) => {
  const EmailInputClass = useThemeStyle(classes, 'EmailInput');
  const [emailInputState, setEmailInputState] = useState({
    viewValue: defaultValue,
    value: defaultValue,
  });
  const [isValid, setIsValid] = useState(null);
  const [error, setError] = useState();

  const validate = () => {
    let valid = true;

    //Validate required
    const validRequire = emailInputState.value.length > 0;
    valid = valid && validRequire;
    if (!validRequire) {
      setError('Giltig e-postadress krävs');
    }

    if (valid) {
      // Validate email
      const validEmail = is.email(emailInputState.value);
      valid = valid && validEmail;
      if (!validEmail) {
        setError('Email has to be in appropriate format');
      }
    }

    setIsValid(valid);
    if (onBlur) {
      onBlur({
        ...emailInputState,
        isValid: valid,
      });
    }
  };

  useEffect(() => {
    if (defaultValue) {
      const inputState = {
        viewValue: defaultValue,
        value: defaultValue,
      };
      setEmailInputState(() => inputState);
      setIsValid(true);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (onBlur) {
      if (onBlur) {
        onBlur({
          ...emailInputState,
          isValid: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailChangeHandler = ({ target: { value } }) => {
    setEmailInputState({
      viewValue: value,
      value,
    });
  };

  const iconCls = [];
  const cls = [EmailInputClass];
  if (isValid === false) {
    cls.push(classes.Invalid);
  }
  if (emailInputState.viewValue.length <= 0) {
    iconCls.push(classes.Empty);
  }

  return (
    <div className={cls.join(' ')}>
      <i className={iconCls.join(' ')}>
        <FontAwesomeIcon icon={faEnvelope} />
      </i>
      {isValid === false ? (
        <span className={classes.ValidationIcon}>
          <CrossInvalidIcon />
        </span>
      ) : isValid === true ? (
        <span className={classes.ValidationIcon}>
          <CheckValidIcon />
        </span>
      ) : null}
      <input
        type='text'
        onBlur={validate}
        placeholder='E-postadress'
        value={emailInputState.viewValue}
        onChange={emailChangeHandler}
        ref={reference}
      />
      {isValid === false ? (
        <div className={classes.ErrorMessage}>
          <p>{error}</p>
        </div>
      ) : null}
    </div>
  );
};

export default EmailInput;

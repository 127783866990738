export const ACTION_SET_USER_INFO = '[user] set user info';
export const ACTION_SET_LOGGED_IN = '[user] set logged in';
export const ACTION_CLEAR_USER_INFO = '[user] clear user info';
export const ACTION_SET_USER_INFO_LOADING = '[user] set user info loading';

export const setUserInfoLoading = (loading) => {
  return {
    type: ACTION_SET_USER_INFO_LOADING,
    payload: { loading },
  };
};

export const setUserInfo = (userInfo) => {
  return {
    type: ACTION_SET_USER_INFO,
    payload: { userInfo },
  };
};

export const clearUserInfo = () => {
  return {
    type: ACTION_CLEAR_USER_INFO,
  };
};

export const setIsLoggedIn = (isLoggedIn) => {
  return {
    type: ACTION_SET_LOGGED_IN,
    payload: { isLoggedIn },
  };
};

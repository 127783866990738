import classes from './InfoBlocks.module.scss';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/sv';
import { selectedSlotObjectSelector } from '../../store/selectors/dateAndTimeStep';
import H from '../UI/Typography/H';

const DateAndTimeInfo = () => {
  const selectedSlot = useSelector(selectedSlotObjectSelector);
  return (
    <Grid item xs={12} className={classes.MeetingInfo}>
      <H s={2}>{capitalize(moment(selectedSlot.start).format('dddd D MMMM'))}</H>
      <H s={2}>
        {moment(selectedSlot.start).format('H.mm')}–{moment(selectedSlot.end).format('H.mm')}
      </H>
    </Grid>
  );
};

function capitalize(str) {
  return str
    .split(' ')
    .map((item) => `${item.slice(0, 1).toUpperCase()}${item.slice(1, item.length)}`)
    .join(' ');
}

export default DateAndTimeInfo;

import {
  ACTION_CLEANUP_FINISH_STEP,
  ACTION_SELECT_MEETING_TYPE,
  ACTION_SET_REGISTER_DATA,
  ACTION_SET_IS_REGISTERING,
  ACTION_SET_REGISTER_RESULTS,
} from '../actions/finishStep';

const initialState = {
  isRegistering: false,
  selectedMeetingType: null,
  registerData: null, // Telephone, email, notes
  registerResults: null,
};

export default function finishStepReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_SET_IS_REGISTERING:
      return {
        ...state,
        isRegistering: action.payload.isRegistering,
      };
    case ACTION_SELECT_MEETING_TYPE:
      return {
        ...state,
        selectedMeetingType: action.payload.name,
      };
    case ACTION_SET_REGISTER_DATA:
      return {
        ...state,
        registerData: action.payload.data,
      };
    case ACTION_CLEANUP_FINISH_STEP:
      return {
        ...state,
        selectedMeetingType: null,
        registerData: null,
      };
    case ACTION_SET_REGISTER_RESULTS:
      return {
        ...state,
        registerResults: action.payload.data,
      };
    default:
      return state;
  }
}

import classes from './DateAndTime.module.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import LineBreak from '../UI/LineBreak/LineBreak';
import Calendar from '../Calendar/Calendar';
import CalendarSlots from '../CalendarSlots/CalendarSlots';
import {
  selectedSlotSelector,
  slotsSelector,
  slotPopUpSelector,
  selectedDateSelector,
  isNextFreeSlotLoadingSelector,
} from '../../store/selectors/dateAndTimeStep';
import { goToNextStep } from '../../store/actions/steps';
import H from '../UI/Typography/H';
import Button from '../UI/Button/Button';
import InstructorSelectPopup from '../InstructorSelectPopup/InstructorSelectPopup';
import {
  filteredInstructorSelector,
  selectedInstructorObjectSelector,
} from '../../store/selectors/startStep';
import { fetchSlots, closePopup, findDateWithFreeSlot } from '../../store/actions/dateAndTimeStep';
import SmallLoader from '../UI/SmallLoader/SmallLoader';

const DateAndTime = ({ disabled }) => {
  const dispatch = useDispatch();
  // STORE
  const selectedDate1 = useSelector(selectedDateSelector);
  const isNextFreeSlotLoading = useSelector(isNextFreeSlotLoadingSelector);
  const selectedSlot = useSelector(selectedSlotSelector);
  const slots = useSelector(slotsSelector);
  const selectedInstructorObj = useSelector(selectedInstructorObjectSelector);
  const filteredInstructor = useSelector(filteredInstructorSelector);
  const slotPopUpOpen = useSelector(slotPopUpSelector).isOpen;

  const calendarSlotsCls = [classes.CalendarSlots];
  const calendarHeaderCls = [classes.CalendarHeader];

  if (!slots.length) {
    calendarSlotsCls.push(classes.NoSlots);
  }
  if (slotPopUpOpen) {
    calendarSlotsCls.push(classes.Muted);
    calendarHeaderCls.push(classes.Muted);
  }

  const submitClickHandler = () => {
    dispatch(goToNextStep());
  };

  const findNextFreeSlotHandler = () => {
    dispatch(findDateWithFreeSlot());
  };

  useEffect(() => {
    dispatch(fetchSlots(selectedDate1));
  }, [dispatch, selectedDate1, filteredInstructor]);

  return (
    <Grid container className={classes.DateAndTime}>
      <Grid item xs={12} className={classes.Header}>
        <H s={2}>Boka tid</H>
        {isMobile && selectedInstructorObj ? (
          <p>
            Visar: <span>{selectedInstructorObj.firstName}</span>
          </p>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <LineBreak />
      </Grid>
      <Grid item xs={12} className={calendarHeaderCls.join(' ')}>
        <Calendar disabled={disabled} />
      </Grid>
      <Grid item xs={12} className={calendarSlotsCls.join(' ')}>
        {disabled ? (
          <CalendarSlots disabled />
        ) : slots.length ? (
          <CalendarSlots />
        ) : (
          <p>Ingen tillgänglig tid</p>
        )}
      </Grid>
      {!disabled ? (
        <Grid item xs={12} className={classes.NextFreeSlot}>
          <Button
            type='secondary'
            caption={isNextFreeSlotLoading ? <SmallLoader /> : 'Visa första lediga tid'}
            onClick={findNextFreeSlotHandler}
          />
        </Grid>
      ) : null}

      <Grid item xs={12} className={classes.Submit}>
        <Button
          caption='Fortsätt'
          onClick={submitClickHandler}
          disabled={disabled || !selectedSlot}
        />
      </Grid>
      {slotPopUpOpen ? (
        <React.Fragment>
          <InstructorSelectPopup />
          <div className={classes.BackLight} onClick={() => dispatch(closePopup())}></div>
        </React.Fragment>
      ) : null}
    </Grid>
  );
};

export default DateAndTime;

import { Redirect, Route, Switch } from 'react-router-dom';
import StartStep from '../containers/StartStep/StartStep';
import DateAndTimeStep from '../containers/DateAndTimeStep/DateAndTimeStep';
import FinishStep from '../containers/FinishStep/FinishStep';
import { stepRoutes, routes } from './routes';
import GuardedRoute from './StepRoute/StepRoute';
import ThankYouPage from '../containers/ThankYouPage/ThankYouPage';
import GeneralError from '../components/GeneralError/GeneralError';
import { isLoggedInSelector } from '../store/selectors/user';
import { useSelector } from 'react-redux';
import Login from '../containers/Login/Login';
import RemoteLogin from '../containers/RemoteLogin/RemoteLogin';
import CancellationPage from '../components/CancellationPage/CancellationPage';
import Logout from '../containers/Logout/Logout';

const Router = () => {
  const LoggedIn = useSelector(isLoggedInSelector);

  return (
    <Switch>
      <Route path={routes.cancellation}>
        <CancellationPage />
      </Route>
      <Route path={routes.logout}>
        <Logout />
      </Route>
      <Route path={routes.login}>{LoggedIn ? <Redirect to={stepRoutes.start} /> : <Login />}</Route>
      <Route path={routes.remoteLogin}>
        <RemoteLogin />
      </Route>
      <Route path={stepRoutes.start}>
        {!LoggedIn ? <Redirect to={routes.login} /> : <StartStep />}
      </Route>

      <GuardedRoute path={stepRoutes.dateAndTime} isStep comp={DateAndTimeStep} />
      <GuardedRoute path={stepRoutes.finish} isStep comp={FinishStep} />
      <GuardedRoute path={routes.thankYou} comp={ThankYouPage} />

      <Route path={routes.errorPage}>
        <GeneralError />
      </Route>
      <Route path={routes.root}>
        <Redirect to={routes.login} />
      </Route>
    </Switch>
  );
};

export default Router;

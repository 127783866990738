import classes from './Typography.module.scss';
import is from 'is_js';
import { isMobile } from 'react-device-detect';
import { useTheme } from '../../../hooks/hooks';

const types = ['intro', 'regular', 'regular-smaller', 'small', 'legal', 'button'];

const Text = ({ type = 'regular', bold, children }) => {
  const [theme] = useTheme();

  if (is.not.inArray(type, types)) {
    throw new Error(`There is incorrect props passed to component: style = ${type}`);
  }
  const cls = [classes.Text, classes[`Style-${type}`], classes[theme]];
  if (bold) {
    cls.push(classes.Bold);
  }
  if (isMobile) {
    cls.push(classes.Mobile);
  }

  return <p className={cls.join(' ')}>{children}</p>;
};

export default Text;

import moment from 'moment';
import { dateFormat } from '../../constants';
import {
  ACTION_CLEANUP_DATE_AND_TIME_STEP,
  ACTION_OPEN_MULTIINSTRUCTOR_POPUP,
  ACTION_SET_NEXT_FREE_SLOT_LOADING,
  ACTION_SET_SELECTED_DATE,
  ACTION_SET_SELECTED_SLOT,
  ACTION_SET_SLOTS,
  ACTION_SET_SLOTS_LOADING,
} from '../actions/dateAndTimeStep';

const initialState = {
  selectedDate: moment().format(dateFormat),
  isNextFreeSlotLoading: false,
  slots: [],
  slotsLoading: false,
  selectedSlot: null,
  slotPopUp: {
    isOpen: false,
    slots: [],
  },
};

export default function dateAndTimeStepReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_SET_SLOTS_LOADING:
      return {
        ...state,
        slotsLoading: action.payload.loading,
      };
    case ACTION_SET_SELECTED_SLOT:
      return {
        ...state,
        selectedSlot: Number(action.payload.id),
      };
    case ACTION_CLEANUP_DATE_AND_TIME_STEP:
      return {
        ...state,
        selectedSlot: null,
      };
    case ACTION_SET_SLOTS:
      return {
        ...state,
        slots: action.payload.slots,
      };
    case ACTION_OPEN_MULTIINSTRUCTOR_POPUP:
      return {
        ...state,
        slotPopUp: { ...action.payload },
      };
    case ACTION_SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload.date,
      };
    case ACTION_SET_NEXT_FREE_SLOT_LOADING:
      return {
        ...state,
        isNextFreeSlotLoading: action.payload.isLoading,
      };
    default:
      return state;
  }
}

import { Grid } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LineBreak from '../UI/LineBreak/LineBreak';
import classes from './RegisterDetails.module.scss';
import RegisterForm from './RegisterForm/RegisterForm';
import { register } from '../../store/actions/finishStep';
import { userInfoSelector } from '../../store/selectors/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import H from '../UI/Typography/H';
import Text from '../UI/Typography/Text';
import Button from '../UI/Button/Button';

const RegisterDetails = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);
  const [isValid, setIsValid] = useState(false);
  const [data, setData] = useState(null);
  const [validateForm, setValidateForm] = useState(false);

  const validateEventHandler = (value) => {
    setIsValid(Boolean(value));
  };

  const registerClickHandler = () => {
    setValidateForm(true);
  };

  const doRegister = useCallback(() => {
    dispatch(register(data));
  }, [data, dispatch]);

  useEffect(() => {
    setValidateForm(false);
    if (isValid) {
      doRegister();
    }
  }, [isValid, doRegister])

  const onDataChangeHandler = (form) => {
    setData(form);
  };

  return (
    <Grid container className={classes.RegisterDetails}>
      <Grid item xs={12} className={classes.Header}>
        <H s={3}>Mina uppgifter</H>
      </Grid>
      <Grid item xs={12}>
        <LineBreak />
      </Grid>
      <Grid item xs={12} className={classes.Details}>
        <Grid item xs={12} className={classes.User}>
          <div>
            <span>
              <FontAwesomeIcon icon={faUser} />
            </span>
            <span>
              <Text type='regular-smaller'>
                {userInfo.firstName} {userInfo.secondName}
              </Text>
            </span>
          </div>
        </Grid>
        <RegisterForm
          onValidate={validateEventHandler}
          onDataChange={onDataChangeHandler}
          defaultEmail={userInfo.email}
          validate={validateForm}
          onValidateEnd={() => setValidateForm(false)}
        />
        <Grid item xs={12} className={classes.SubmitButton}>
          <Button
            caption='Slutför bokning'
            // disabled={!isValid || !Boolean(selectedMeetingType)}
            onClick={registerClickHandler}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegisterDetails;

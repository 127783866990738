import classes from './App.module.scss';
import Layout from './layout/Layout';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Router from './router/Router';
import { isLoggedInSelector } from './store/selectors/user';
import { fetchUserData, fetchWelcomeText, setTheme } from './store/actions/common';
import { isLoggedIn } from './cookies/cookies';
import { setIsLoggedIn } from './store/actions/user';
import { useLocation } from 'react-router';
import { routes } from './router/routes';
import MobileMenu from './components/MobileMenu/MobileMenu';
import { useIsAdmin } from './hooks/hooks';
const THEME = process.env.REACT_APP_THEME || 'basic';

const App = () => {
  const dispatch = useDispatch();
  const LoggedIn = useSelector(isLoggedInSelector);
  const location = useLocation();
  const isAdmin = useIsAdmin();

  const isLayoutBlank = () => {
    return !LoggedIn || location.pathname.match(routes.cancellation);
  };

  useEffect(() => {
    dispatch(setTheme(THEME));
  });

  useEffect(() => {
    if (LoggedIn) {
      dispatch(fetchUserData());
      dispatch(fetchWelcomeText());
    } else {
      const cookiesLogin = isLoggedIn();
      if (cookiesLogin) {
        dispatch(setIsLoggedIn(true));
      }
    }
  }, [LoggedIn, dispatch]);

  return (
    <div className={classes.App}>
      <Layout blank={isLayoutBlank()}>
        <Router basename={'/app'} />
      </Layout>
      <MobileMenu isAdmin={isAdmin} />
    </div>
  );
};

export default App;

import classes from './StepsContainer.module.scss';
import { Grid } from '@material-ui/core';

const StepsContainer = ({ children }) => {
  return (
    <Grid container className={classes.StepsContainer}>
      {children}
    </Grid>
  );
};

export default StepsContainer;

import classes from './ServiceTypes.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import LineBreak from '../UI/LineBreak/LineBreak';
import {
  serviceTypesSelector,
  selectedServiceSelector,
  isServiceLoadingSelector,
} from '../../store/selectors/startStep';
import { fetchServiceTypes, selectService } from '../../store/actions/startStep';
import H from '../UI/Typography/H';
import Text from '../UI/Typography/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentsAlt, faUserFriends } from '@fortawesome/pro-light-svg-icons';
import RoundButton from '../UI/RoundButton/RoundButton';
import Loader from '../UI/Loader/Loader';

const ServiceTypes = () => {
  const loading = useSelector(isServiceLoadingSelector);
  const dispatch = useDispatch();
  const services = useSelector(serviceTypesSelector);
  const selectedServices = useSelector(selectedServiceSelector);
  const serviceClickHandler = (id) => {
    dispatch(selectService(id));
  };

  useEffect(() => {
    dispatch(fetchServiceTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderServices = () => {
    return services.map((service) => {
      const selected = service.id === selectedServices;
      const cls = [classes.Service];

      return (
        <Grid item key={service.id}>
          <div className={cls.join(' ')}>
            <RoundButton
              selected={selected}
              onClick={!selected ? () => serviceClickHandler(service.id) : undefined}
            >
              <FontAwesomeIcon
                icon={Number(service.max_members) === 1 ? faCommentsAlt : faUserFriends}
              />
            </RoundButton>
            <span onClick={!selected ? () => serviceClickHandler(service.id) : undefined}>
              <Text type='button'>{service.name}</Text>
            </span>
          </div>
        </Grid>
      );
    });
  };

  return (
    <Grid item xs={12} className={classes.ServiceTypes}>
      <Grid item xs={12} className={classes.Header}>
        <H s={2}>Välj tjänst</H>
      </Grid>
      <LineBreak />
      {loading ? <Loader /> : <Grid container>{renderServices()}</Grid>}
    </Grid>
  );
};

export default ServiceTypes;

import { STEP_STATUS, STEP_CODES } from '../../constants';
import {
  ACTION_SET_CURRENT_STEP,
  ACTION_SET_HAS_NEXT_STEP,
  ACTION_SET_STEPS_LIST,
} from '../actions/steps';

const initialState = {
  sequence: [STEP_CODES.START, STEP_CODES.DATE_AND_TIME, STEP_CODES.FINISH],
  list: [
    {
      id: 1,
      label: 'Start',
      status: STEP_STATUS.ACTIVE,
      code: STEP_CODES.START,
    },
    {
      id: 2,
      label: 'Välj tid',
      status: STEP_STATUS.PENDING,
      code: STEP_CODES.DATE_AND_TIME,
    },
    {
      id: 3,
      label: 'Slutför',
      status: STEP_STATUS.PENDING,
      code: STEP_CODES.FINISH,
    },
  ],
  currentStep: null,
  hasNextStep: true,
};

export default function StepsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_SET_STEPS_LIST:
      return {
        ...state,
        list: action.payload.list,
      };
    case ACTION_SET_HAS_NEXT_STEP:
      return { ...state, hasNextStep: action.payload.has };
    case ACTION_SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload.step,
      };
    default:
      return state;
  }
}

import { getAxios } from '../../axios/axiosApi';
import { selectInstructor } from './startStep';
import is from 'is_js';

export const ACTION_SET_SELECTED_SLOT = '[date and time step] Set selected slot';
export const ACTION_SET_SELECTED_DATE = '[date and time step] Set selected date';
export const ACTION_SET_SLOTS_LOADING = '[date and time step] Set slots loading';
export const ACTION_SET_NEXT_FREE_SLOT_LOADING = '[date and time step] Set next free slots loading';
export const ACTION_CLEANUP_DATE_AND_TIME_STEP = '[date and time step] Cleanup';
export const ACTION_SET_SLOTS = '[date and time step] Set slots';
export const ACTION_OPEN_MULTIINSTRUCTOR_POPUP = '[date and time step] open multiinstructor popup';
export const ACTION_CLOSE_MULTIINSTRUCTOR_POPUP =
  '[date and time step] close multiinstructor popup';

export const selectSlot = (id) => {
  return (dispatch, getState) => {
    dispatch(setSelectedSlot(id));
    const instructorId = getState().dateAndTimeStep.slots.find((slot) => {
      return id === slot.id;
    }).providerId;
    dispatch(selectInstructor(instructorId));
  };
};

export const setSelectedSlot = (id) => {
  return {
    type: ACTION_SET_SELECTED_SLOT,
    payload: { id },
  };
};

export const cleanupDateAndTimeStep = () => {
  return {
    type: ACTION_CLEANUP_DATE_AND_TIME_STEP,
  };
};

export const setSlotsLoading = (loading) => {
  return {
    type: ACTION_SET_SLOTS_LOADING,
    payload: { loading },
  };
};

export const fetchSlots = (date) => {
  return async (dispatch, getState) => {
    if (!date) {
      return;
    }
    try {
      dispatch(setSlotsLoading(true));
      const {
        startStep: { filteredInstructor, instructors, selectedService },
        user: { userInfo },
      } = getState();

      if (filteredInstructor !== null && selectedService && userInfo.service) {
        const params = {
          params: {
            date,
            serviceTypeId: selectedService,
            serviceId: userInfo.service.id,
            providerId:
              filteredInstructor === 0 ? instructors.map((i) => i.id) : [filteredInstructor],
          },
        };
        const slotsRequest = await getAxios(dispatch).get('appointments', params);

        // Hotfix BE issue. Slots request returns object instead of array (somehow)
        let slots = slotsRequest.data;
        if (is.not.array(slots)) {
          slots = Object.values(slots);
        }

        dispatch(setSlots(slots));
        dispatch(setSlotsLoading(false));
      }
    } catch (e) {
      dispatch(setSlotsLoading(false));
      console.error(e);
    }
  };
};

export const setSlots = (slots) => {
  return {
    type: ACTION_SET_SLOTS,
    payload: { slots },
  };
};

export const openPopup = (slots) => {
  return (dispatch) => {
    dispatch(setSlotPopup(true, slots));
  };
};

export const closePopup = () => {
  return (dispatch) => {
    dispatch(setSlotPopup(false, []));
  };
};

export const setSlotPopup = (isOpen, slots) => {
  return {
    type: ACTION_OPEN_MULTIINSTRUCTOR_POPUP,
    payload: { isOpen, slots },
  };
};
export const setSelectedDate = (date) => {
  return {
    type: ACTION_SET_SELECTED_DATE,
    payload: { date },
  };
};

export const setNextFreeSlotLoading = (isLoading) => {
  return {
    type: ACTION_SET_NEXT_FREE_SLOT_LOADING,
    payload: { isLoading },
  };
};

export const findDateWithFreeSlot = () => {
  return async (dispatch, getState) => {
    dispatch(setNextFreeSlotLoading(true));

    const { selectedService, filteredInstructor } = getState().startStep;

    const options = {
      params: { service_id: selectedService },
    };

    if (filteredInstructor) {
      options.params.provider_id = filteredInstructor;
    }

    const nearestDateRequest = await getAxios(dispatch).get('get-nearest-date', options);
    if (nearestDateRequest.data.date) {
      dispatch(setSelectedDate(nearestDateRequest.data.date));
    }
    dispatch(setNextFreeSlotLoading(false));
  };
};

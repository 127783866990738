import classes from './RoundButton.module.scss';
import { useThemeStyle } from '../../../hooks/hooks';

const RoundButton = ({disabled, selected, onClick, bg = 'white', children}) => {
    const RoundButtonClass = useThemeStyle(classes, 'RoundButton');
    const cls = [RoundButtonClass, classes[`Bg-${bg}`]];
    if (selected) {
        cls.push(classes.Selected);
    }
    
    return (
        <button disabled={disabled} onClick={onClick} className={cls.join(' ')}>{children}</button>
    );
}

export default RoundButton;
import { history } from '../configureStore';
import { routes } from '../../router/routes';
import { getAxios } from '../../axios/axiosApi';

export const ACTION_SELECT_MEETING_TYPE = '[finish step] select meeting type';
export const ACTION_SET_REGISTER_DATA = '[finish step] set register data';
export const ACTION_CLEANUP_FINISH_STEP = '[finish step] cleanup';
export const ACTION_SET_IS_REGISTERING = '[finish step] set is registering';
export const ACTION_SET_REGISTER_RESULTS = '[finish step] set is register results';

export const selectMeetingType = (name) => {
  return {
    type: ACTION_SELECT_MEETING_TYPE,
    payload: { name },
  };
};

export const setIsRegistering = (isRegistering) => {
  return {
    type: ACTION_SET_IS_REGISTERING,
    payload: { isRegistering },
  };
};

export const setRegisterResults = (data) => {
  return {
    type: ACTION_SET_REGISTER_RESULTS,
    payload: { data },
  };
};

export const register = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsRegistering(true));
      dispatch(setRegisterData(data));
      history.push(routes.thankYou);
      const {
        finishStep: { selectedMeetingType },
        dateAndTimeStep: { selectedSlot },
      } = getState();
      const formData = new FormData();
      formData.append('appointment_id', selectedSlot);
      formData.append('email', data.email.value);
      formData.append('phone_number', data.phone.value);
      formData.append('appointment_type', selectedMeetingType);
      if (data.notes.value) {
        formData.append('comment', data.notes.value);
      }
      const registerRequest = await getAxios(dispatch).post('assign-to-appointment', formData);

      dispatch(setRegisterResults(registerRequest.data));
      dispatch(setIsRegistering(false));
    } catch (e) {
      dispatch(setIsRegistering(false));
      console.error(e);
    }
  };
};

export const setRegisterData = (data) => {
  return {
    type: ACTION_SET_REGISTER_DATA,
    payload: { data },
  };
};

export const cleanupFinishStep = () => {
  return {
    type: ACTION_CLEANUP_FINISH_STEP,
  };
};

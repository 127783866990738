import classes from './InstructorSelectPopup.module.scss';
import RoundButton from '../UI/RoundButton/RoundButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { useState } from 'react';
import Text from '../UI/Typography/Text';
import H from '../UI/Typography/H';
import LineBreak from '../UI/LineBreak/LineBreak';
import Button from '../UI/Button/Button';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { slotPopUpSelector } from '../../store/selectors/dateAndTimeStep';
import { instructorsSelector } from '../../store/selectors/startStep';
import { selectSlot, closePopup } from '../../store/actions/dateAndTimeStep';
import { useThemeStyle, useTheme } from '../../hooks/hooks';

const InstructorSelectPopup = () => {
  const InstructorSelectPopupClass = useThemeStyle(classes, 'InstructorSelectPopup');
  const [theme, themes] = useTheme();
  const dispatch = useDispatch();
  const [selectedSlotId, setSelectedSlotId] = useState();
  const popupSlots = useSelector(slotPopUpSelector).slots;
  const instructors = useSelector(instructorsSelector);
  const submitBtnClickHandler = () => {
    dispatch(selectSlot(selectedSlotId));
    dispatch(closePopup());
  };

  const getInstructorFromSlot = (slot) => {
    return instructors.find((ins) => ins.id === slot.providerId);
  };

  const renderList = () => {
    return popupSlots.map(({ isAvailable, slot }) => {
      const cls = [classes.Instructor];
      const selected = slot.id === selectedSlotId;

      return (
        <Grid item xs={12} className={cls.join(' ')} key={slot.id}>
          <RoundButton
            bg={theme === themes.BASIC ? 'grey' : 'white' }
            selected={selected}
            onClick={isAvailable ? () => setSelectedSlotId(slot.id) : null}
            disabled={!isAvailable}
          >
            <FontAwesomeIcon icon={faUser} />
          </RoundButton>
          <span className={!isAvailable ? classes.Grayscale : null}>
            <Text type='button'>{getInstructorFromSlot(slot).firstName} {getInstructorFromSlot(slot).lastName}</Text>
          </span>
        </Grid>
      );
    });
  };

  return (
    <div className={InstructorSelectPopupClass}>
      <div className={classes.Wrap}>
        <Grid container>
          <Grid item xs={12}>
            <H s={3}>Välj person</H>
          </Grid>
          <Grid item xs={12}>
            <LineBreak />
          </Grid>
          {renderList()}
          <Button
            disabled={!Boolean(selectedSlotId)}
            caption='Fortsätt'
            onClick={submitBtnClickHandler}
          />
        </Grid>
      </div>
    </div>
  );
};

export default InstructorSelectPopup;

import { Grid } from '@material-ui/core';
import classes from './InfoBlocks.module.scss';
import { useSelector } from 'react-redux';
import { selectedMeetingTypeSelector } from '../../store/selectors/finishStep';
import { MEETING_TYPES } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faVideo, faUsers } from '@fortawesome/pro-light-svg-icons';
import H from '../UI/Typography/H';
import Text from '../UI/Typography/Text';

const MeetingTypeInfo = () => {
  const selectedMeetingType = useSelector(selectedMeetingTypeSelector);
  const meetingTypes = mapMeetingTypeToIcon();

  const renderType = () => {
    const typeToRender = meetingTypes[selectedMeetingType];
    const { Icon, className, description } = typeToRender;
    const cls = [];
    if (className) {
      cls.push(className);
    }
    return (
      <Grid item xs={12} className={classes.Info}>
        <span className={cls.join(' ')}>
          <FontAwesomeIcon icon={Icon} />
        </span>
        <div>
          <Text>{description}</Text>
        </div>
      </Grid>
    );
  };

  return (
    <Grid item xs={12} className={classes.MeetingTypeInfo}>
      <Grid item xs={12}>
        <H s={3}>Mötestyp</H>
      </Grid>
      {renderType()}
    </Grid>
  );
};

function mapMeetingTypeToIcon() {
  return {
    [MEETING_TYPES.telephone.id]: {
      ...MEETING_TYPES.telephone,
      Icon: faPhone,
    },
    [MEETING_TYPES.chat.id]: {
      ...MEETING_TYPES.chat,
      Icon: faVideo,
    },
    [MEETING_TYPES.physical.id]: {
      ...MEETING_TYPES.physical,
      Icon: faUsers,
      className: classes.PeopleSmallIcon,
    },
  };
}

export default MeetingTypeInfo;

import { useEffect } from 'react';
import Steps from '../../components/Steps/Steps';
import { isMobile } from 'react-device-detect';
import Welcome from '../../components/Welcome/Welcome';
import StepsContainer from '../StepsContainer/StepsContainer';
import { Grid } from '@material-ui/core';
import ServiceTypes from '../../components/ServiceTypes/ServiceTypes';
import Instructors from '../../components/Instructors/Instructors';
import DateAndTimeComponent from '../../components/DateAndTime/DateAndTime';

const DateAndTime = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Steps />
      {isMobile ? null : <Welcome />}
      <StepsContainer>
        {isMobile ? null : (
          <Grid item xs={12} md={6} style={{ zIndex: 1 }}>
            <ServiceTypes />
            <Instructors />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <DateAndTimeComponent />
        </Grid>
      </StepsContainer>
    </div>
  );
};

export default DateAndTime;

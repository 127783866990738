import classes from './Steps.module.scss';
import React from 'react';
import { useHistory } from 'react-router';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { stepsSelector } from '../../store/selectors/steps';
import { STEP_STATUS } from '../../constants';
import { goToSelectedStep } from '../../store/actions/steps';
import { useThemeStyle } from '../../hooks/hooks';
import Text from '../UI/Typography/Text';

const Steps = () => {
  const StepsWrapClass = useThemeStyle(classes, 'StepsWrap');
  const dispatch = useDispatch();
  const history = useHistory();
  const steps = useSelector(stepsSelector);

  const stepClickHandler = (code) => {
    dispatch(goToSelectedStep(code, history));
  };

  const renderSteps = () => {
    return steps.map((step, index) => {
      const cls = [classes.Step];

      if (step.status === STEP_STATUS.ACTIVE) {
        cls.push(classes.Selected);
      } else if (step.status === STEP_STATUS.PASSED) {
        cls.push(classes.Passed);
      }

      return (
        <React.Fragment key={step.id}>
          <div className={cls.join(' ')}>
            <button
              onClick={
                step.status === STEP_STATUS.PASSED ? () => stepClickHandler(step.code) : undefined
              }
            >
              {step.id}
            </button>
            <span className={classes.Label}>
              <Text type='small' bold>
                {step.label}
              </Text>
            </span>
          </div>
          {index + 1 !== steps.length ? <div className={classes.Line}></div> : null}
        </React.Fragment>
      );
    });
  };

  return (
    <Grid container style={{ zIndex: 1 }}>
      <Grid item xs={12}>
        <div className={StepsWrapClass}>
          <div className={classes.Steps}>{renderSteps()}</div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Steps;

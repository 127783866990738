import classes from './Welcome.module.scss';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { userInfoSelector, isUserInfoLoadingSelector } from '../../store/selectors/user';
import H from '../UI/Typography/H';
import Text from '../UI/Typography/Text';
import { welcomeTextSelector, isWelcomeTextLoadingSelector } from '../../store/selectors/common';
import React from 'react';
import Loader from '../UI/Loader/Loader';

const Welcome = () => {
  const userInfo = useSelector(userInfoSelector);
  const welcomeText = useSelector(welcomeTextSelector);
  const isWelcomeTextLoading = useSelector(isWelcomeTextLoadingSelector);
  const isUserInfoLoading = useSelector(isUserInfoLoadingSelector);

  const renderContent = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} style={{ zIndex: 1 }}>
          <H s={1}>Välkommen {userInfo.firstName}!</H>
        </Grid>
        <Grid item xs={12} className={classes.Text} style={{ zIndex: 1 }}>
          <Text type='intro'>{welcomeText}</Text>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Grid container className={classes.Welcome}>
      {isWelcomeTextLoading || isUserInfoLoading ? <Loader /> : renderContent()}
    </Grid>
  );
};

export default Welcome;

import classes from './SmallLoader.module.scss';

const SmallLoader = () => {
  return (
    <div className={classes.SmallLoader}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default SmallLoader;

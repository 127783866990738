import classes from './Layout.module.scss';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Content from './Content/Content';
import { useRef, useEffect, useState } from 'react';
import Loader from '../components/UI/Loader/Loader';
import { useThemeStyle } from '../hooks/hooks';

const Layout = ({ blank, children }) => {
  const LayoutClass = useThemeStyle(classes, 'Layout');
  const [loading, setLoading] = useState(true);
  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      setLoading(() => {
        return false;
      });
    }
  }, [setLoading]);

  return loading ? (
    <Loader />
  ) : blank ? (
    children
  ) : (
    <div className={LayoutClass}>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </div>
  );
};

export default Layout;

import classes from './Login.module.scss';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import H from '../../components/UI/Typography/H';
import Button from '../../components/UI/Button/Button';
import { useEffect } from 'react';
import { cleanUpForStepBack } from '../../store/actions/steps';
import {removeIsAcademediaLogin, removeRemoteSecret, setIsAcademediaLogin} from '../../localStorage/localStorage';
import { removeAllCookies } from '../../cookies/cookies';
import { useTheme } from '../../hooks/hooks';
const { REACT_APP_ADMIN_LOGIN_PAGE: link, REACT_APP_BASE_URL: publicURL } = process.env;

const Login = () => {
  const dispatch = useDispatch();
  const [theme, themes] = useTheme();

  useEffect(() => {
    dispatch(cleanUpForStepBack(0));
    removeRemoteSecret();
    removeAllCookies();
  }, [dispatch]);

  const renderTitle = () => {
    switch (theme) {
      case themes.EXTERNAL:
        return 'Global Education Services.';
      case themes.BASIC:
        return 'Snacka med din skola.';
      default:
        return 'Snacka med din skola.';
    }
  };

  const encodeUrl = window.btoa(publicURL);

  const handleLoginClick = () => {
    removeIsAcademediaLogin();
    window.location.href = `${link}user/grandid_login?frontend_url=${encodeUrl}`;
  };

  const handleLoginClickAcademedia = () => {
    setIsAcademediaLogin(true);
    window.location.href = `${link}user/academedia_login?frontend_url=${encodeUrl}`;
  };

  return (
    <div className={classes.Login}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.WelcomeText}>
            <H s={1} display>
              {renderTitle()}
            </H>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.LoginButton} style={{display: theme === themes.EXTERNAL && 'none' }}>
          <div>
            <Button caption='Logga in med Academedia' onClick={handleLoginClick} />
          </div>
        </Grid>
        {theme === themes.EXTERNAL && (
          <Grid item xs={12} className={classes.LoginButton}>
            <div className={classes.LoginAcademedia}>
              <Button caption='Logga in' type='third' onClick={handleLoginClickAcademedia} />
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default Login;

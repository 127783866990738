import { ReactComponent as LogoSVGBasic } from './assets/logo_basic.svg';
import { ReactComponent as LogoSVGExternal } from './assets/logo_external.svg';
import { ReactComponent as LogoSVGExternalSmall } from './assets/logo_external_small.svg';
import { routes } from '../../router/routes';
import { useTheme } from '../../hooks/hooks';
import { isMobile } from 'react-device-detect';

const Logo = () => {
  const [theme, themes] = useTheme();

  const renderLogo = () => {
    switch (theme) {
      case themes.EXTERNAL:
        return isMobile ? <LogoSVGExternalSmall /> : <LogoSVGExternal />;
      case themes.BASIC:
        return <LogoSVGBasic />;
      default:
        return <LogoSVGBasic />;
    }
  };

  return <a href={routes.root}>{renderLogo()}</a>;
};

export default Logo;

import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { userInfoSelector } from '../../store/selectors/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHomeAlt } from '@fortawesome/pro-light-svg-icons';
import H from '../UI/Typography/H';
import Text from '../UI/Typography/Text';
import classes from './InfoBlocks.module.scss';

const UnitInfo = () => {
  const userInfo = useSelector(userInfoSelector);
  return userInfo.unitName ? (
    <Grid item xs={12} className={classes.Unit}>
      <Grid item xs={12}>
        <H s={3}>Enhet</H>
      </Grid>
      <Grid item xs={12} className={classes.Info}>
        <span>
          <FontAwesomeIcon icon={faHomeAlt} />
        </span>
        <Text>{userInfo.unitName}</Text>
      </Grid>
    </Grid>
  ) : null;
};

export default UnitInfo;

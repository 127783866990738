import { Route, Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { currentStepSelector, stepsSelector } from '../../store/selectors/steps';
import { stepRoutes } from '../routes';
import { STEP_CODES, STEP_STATUS } from '../../constants';
import { isLoggedInSelector } from '../../store/selectors/user';


const GuardedRoute = ({ comp: Component, path, isStep, ...rest }) => {
  const steps = useSelector(stepsSelector);
  const currentStep = useSelector(currentStepSelector)
  const LoggedIn = useSelector(isLoggedInSelector);

  const guardStep = () => {
    const stepToCheck = steps.find((step) => step.code === mapRouteToCode[path]);
    return stepToCheck.status !== STEP_STATUS.PENDING;
  }

  const guardRoute = () => {
    return Boolean(currentStep);
  }

  const guardAuth = () => {
    return LoggedIn;
  }

  const isRouteAccessible = () => {
    let accessible = true;
    if (isStep) {
      accessible = accessible && guardStep();
    }
    accessible = accessible && guardRoute() && guardAuth();
    return accessible;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        return isRouteAccessible() ? (<Component {...props} />) : (<Redirect to={'/login'} />);
      }}
    />
  );
};

const mapRouteToCode = {
  [stepRoutes.start]: STEP_CODES.START,
  [stepRoutes.dateAndTime]: STEP_CODES.DATE_AND_TIME,
  [stepRoutes.finish]: STEP_CODES.FINISH,
};

export default GuardedRoute;

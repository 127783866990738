import classes from './InfoBlocks.module.scss';
import moment from 'moment';
import 'moment/locale/sv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import { selectedSlotObjectSelector } from '../../store/selectors/dateAndTimeStep';
import { Grid } from '@material-ui/core';
import H from '../UI/Typography/H';
import Text from '../UI/Typography/Text';

const DurationInfo = () => {
  const selectedSlot = useSelector(selectedSlotObjectSelector);

  return (
    <Grid item xs={12} className={classes.Duration}>
      <Grid item xs={12}>
        <H s={3}>Längd</H>
      </Grid>
      <Grid item xs={12} className={classes.Info}>
        <span>
          <FontAwesomeIcon icon={faClock} />
        </span>
        <div>
          <Text type='regular'>
            {moment(selectedSlot.end).diff(selectedSlot.start, 'minutes')} min
          </Text>
        </div>
      </Grid>
    </Grid>
  );
};

export default DurationInfo;

import { Grid } from '@material-ui/core';
import classes from './GeneralError.module.scss';
import H from '../UI/Typography/H';
import Text from '../UI/Typography/Text';

const GeneralError = () => {
  return (
    <Grid
      container
      className={classes.GeneralError}
      direction='column'
      alignItems='center'
      justify='center'
    >
      <section>
        <Grid item xs={12} className={classes.AlertSign}>
          <span>!</span>
        </Grid>
        <Grid item xs={12} className={classes.Header}>
          <H s={2}>Något gick fel!</H>
        </Grid>
        <Grid item xs={12}>
          <Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis dui purus risus fames
            bibendum arcu.
          </Text>
        </Grid>
      </section>
    </Grid>
  );
};

export default GeneralError;

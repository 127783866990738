import ls from 'local-storage';

const REMOTE_SECRET_NAME = 'remoteSecret';
const IS_ACADEMEDIA_LOGIN_NAME = 'academedia_login';

export function setRemoteSecret(secret) {
  ls(REMOTE_SECRET_NAME, secret);
}

export function removeRemoteSecret() {
  ls.remove(REMOTE_SECRET_NAME);
}

export function getRemoteSecret() {
  return ls(REMOTE_SECRET_NAME);
}

export function setIsAcademediaLogin(value) {
  ls(IS_ACADEMEDIA_LOGIN_NAME, value);
}

export function removeIsAcademediaLogin() {
  ls.remove(IS_ACADEMEDIA_LOGIN_NAME);
}

export function getIsAcademediaLogin() {
  return ls(IS_ACADEMEDIA_LOGIN_NAME);
}

import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { stepRoutes } from '../../router/routes';
import { login } from '../../store/actions/common';
import { useQuery } from '../../hooks/hooks';
import { isLoggedInSelector } from '../../store/selectors/user';
import Loader from '../../components/UI/Loader/Loader';
import { Grid } from '@material-ui/core';
import { setRemoteSecret } from '../../localStorage/localStorage';

const RemoteLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const LoggedIn = useSelector(isLoggedInSelector);

  if (query.has('ident')) {
    const secret = query.get('ident');
    dispatch(login(secret));
    setRemoteSecret(secret);
  } else {
    history.push('/');
  }

  const renderLoader = () => {
    return (
      <Grid
        container
        direction='column'
        alignItems='center'
        justify='center'
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <Loader />
        </Grid>
      </Grid>
    );
  };

  return LoggedIn ? <Redirect to={stepRoutes.start} /> : renderLoader();
};

export default RemoteLogin;

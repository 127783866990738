import classes from './Footer.module.scss';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import { ReactComponent as LogoSVGExternal } from '../assets/logo_external.svg';
import { Grid } from '@material-ui/core';
import Text from '../../components/UI/Typography/Text';
import Bluewave from '../Waves/Bluewave';
import { useThemeStyle, useTheme, useThemeWaves } from '../../hooks/hooks';

const Footer = () => {
  const FooterClass = useThemeStyle(classes, 'Footer');
  const [theme, themes] = useTheme();
  const showWaves = useThemeWaves();

  const renderLogo = () => {
    switch (theme) {
      case themes.BASIC:
        return <LogoSVG />;
      case themes.EXTERNAL:
        return <LogoSVGExternal />;
      default:
        return <LogoSVG />;
    }
  };

  return (
    <div className={FooterClass}>
      <footer>
        <Grid container>
          <Grid item xs={8} md={4}>
            <div className={classes.Logo}>{renderLogo()}</div>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.GeneralInfo}>
                {theme !== themes.EXTERNAL ? (
                  <Text type={'small'}>
                    AcadeMedia är Norra Europas största utbildningsföretag. Vi är verksamma i hela
                    utbildningskedjan; förskola, grundskola, gymnasium och vuxenutbildning. Mer
                    information finns på academedia.se.
                  </Text>
                ) : (<>
                  <Text type={'small'} bold>
                    Kort om oss
                  </Text>
                  <Text type={'small'}>
                    Global Education Services erbjuder studie-och yrkesvägledning till skolor och företag
                    som saknar eller vill utöka tillgängligheten till en studie- och yrkesvägledare.
                  </Text></>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.ContactInfo}>
                  {theme !== themes.EXTERNAL ? (
                    <>
                      <div>
                        <Text type={'small'} bold>
                          Kontakt
                        </Text>
                        <Text type={'small'}>
                          <a href='mailto:info@snackamedsyv.se'>info@snackamedsyv.se</a>
                        </Text>
                      </div>
                      <div>
                        <Text type={'small'} bold>
                          Genvägar
                        </Text>
                        <Text type={'small'}>
                          <a href='acui.my-dev.org/cookies'>
                            Hantering av personuppgifter Cookiepolicy
                          </a>
                        </Text>
                      </div>
                    </>
                  ) : (
                    <div>
                      <Text type={'small'} bold>
                        <a href='//globaleducation.se/kontakta-oss'>Kontakt</a>
                      </Text>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.CookiesDisclaimer}>
                  <Text type={'legal'}>
                    Vi använder cookies för att mäta trafiken på hemsidan och för statistik.
                    Informationen som samlas in är anonym.
                  </Text>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </footer>
      {showWaves ? <Bluewave /> : null}
    </div>
  );
};

export default Footer;

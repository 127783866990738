import classes from './Calendar.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import 'moment/locale/sv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { setSelectedSlot, setSelectedDate } from '../../store/actions/dateAndTimeStep';
import { selectedDateSelector } from '../../store/selectors/dateAndTimeStep';
import { dateFormat } from '../../constants';
import { useThemeStyle } from '../../hooks/hooks';
moment.locale('sv');

const Calendar = ({ disabled }) => {
  const CalendarClass = useThemeStyle(classes, 'Calendar');
  const dispatch = useDispatch();
  const today = moment().format(dateFormat);
  const [state, setState] = useState({
    selectedWeek: {
      start: getWeekStart(moment().format(dateFormat)).format(dateFormat),
      end: getWeekEnd(moment().format(dateFormat)).format(dateFormat),
    },
  });
  const selectedDate = useSelector(selectedDateSelector);

  const goToWeekBeforeHandler = () => {
    const prevWeekEnd = moment(state.selectedWeek.start).subtract(1, 'days');
    const prevWeekStart = prevWeekEnd.clone().subtract(6, 'days');
    setState({
      ...state,
      selectedWeek: {
        start: prevWeekStart.format(dateFormat),
        end: prevWeekEnd.format(dateFormat),
      },
    });
  };

  const goToWeekNextHandler = () => {
    const nextWeekStart = moment(state.selectedWeek.end).add(1, 'days');
    const nextWeekEnd = nextWeekStart.clone().add(6, 'days');
    setState({
      ...state,
      selectedWeek: {
        start: nextWeekStart.format(dateFormat),
        end: nextWeekEnd.format(dateFormat),
      },
    });
  };

  const daySelectHandler = (day) => {
    const date = day.format(dateFormat);
    dispatch(setSelectedDate(date));
    dispatch(setSelectedSlot(null));
  };

  useEffect(() => {
    setState(() => {
      return {
        selectedWeek: {
          start: getWeekStart(moment(selectedDate).format(dateFormat)).format(dateFormat),
          end: getWeekEnd(moment(selectedDate).format(dateFormat)).format(dateFormat),
        },
      };
    });
  }, [selectedDate]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedDate(moment().format(dateFormat)));
    };
  }, [dispatch]);

  const renderWeek = () => {
    const moments = generateMomentsRange(state.selectedWeek.start, today);
    return moments.map((m, i) => {
      const dayOfWeek = m.day();
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
      const isSelected = m.isSame(selectedDate, 'day');
      const isToday = m.isSame(today, 'day');
      const isBefore = m.isBefore(today, 'day');
      const cls = [classes.Day];
      if (isWeekend) {
        cls.push(classes.Weekend);
      }
      if (isSelected) {
        cls.push(classes.Selected);
      }
      if (isToday) {
        cls.push(classes.Today);
      }
      return (
        <Grid
          item
          xs
          className={cls.join(' ')}
          key={i}
          onClick={isSelected || isBefore ? undefined : () => daySelectHandler(m)}
        >
          <span>{m.format('ddd').charAt(0).toUpperCase() + m.format('ddd').slice(1)}</span>
          <span>{m.format('DD/MM')}</span>
          <span className={classes.Triangle}></span>
        </Grid>
      );
    });
  };

  const containerClasses = [CalendarClass];
  const isDisabledPrevButton = disablePrevButton(state.selectedWeek.start, today);
  if (disabled) {
    containerClasses.push(classes.Disabled);
  }
  if (isMobile) {
    containerClasses.push(classes.Mobile);
  }

  return (
    <Grid container className={containerClasses.join(' ')}>
      <Grid item xs className={[classes.Arrow, classes.Left].join(' ')}>
        <button onClick={goToWeekBeforeHandler} disabled={disabled || isDisabledPrevButton}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </Grid>
      <Grid item xs={5} className={classes.DateRange}>
        <p>{getStartEndWeekString(state.selectedWeek.start)}</p>
      </Grid>
      <Grid item xs className={[classes.Arrow, classes.Right].join(' ')}>
        <button onClick={goToWeekNextHandler} disabled={disabled}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </Grid>
      <Grid container className={classes.WeeksRow}>
        <div className={isMobile ? classes.Mobile : ''}>{renderWeek()}</div>
      </Grid>
    </Grid>
  );
};

function disablePrevButton(start, today) {
  const weekStart = getWeekStart(start);
  const isBefore = weekStart.isBefore(today, 'day');
  const isToday = weekStart.isSame(today, 'day');

  return isBefore || isToday;
}

function generateMomentsRange(start) {
  const datesRange = [];

  const weekStart = getWeekStart(start);

  for (let i = 0; i < 7; i++) {
    datesRange.push(moment(weekStart).add(i, 'days'));
  }
  return datesRange;
}

function getStartEndWeekString(start) {
  const weekStart = getWeekStart(start);
  const weekEnd = getWeekEnd(start);
  return `${weekStart.format('DD MMMM')} - ${weekEnd.format('DD MMMM')}`;
}

function getWeekStart(start) {
  return moment(start).startOf('isoWeek');
}

function getWeekEnd(start) {
  return moment(start).endOf('isoWeek');
}

export default Calendar;

import { STEP_CODES } from '../../constants';
import { goToSelectedStep, cleanUpForStepBack } from './steps';
import { getAxios } from '../../axios/axiosApi';

export const ACTION_SET_SELECTED_SERVICE = '[start step] Set selected service';
export const ACTION_SET_SELECTED_INSTRUCTOR = '[start step] Set selected instructor';
export const ACTION_SET_FILTERED_INSTRUCTOR = '[start step] Set filtered instructor';
export const ACTION_SET_INSTRUCTORS_LIST = '[start step] set instructors list';
export const ACTION_SET_SERVICE_TYPES = '[start step] set service types';
export const ACTION_SET_SERVICE_LOADING = '[start step] set service loading';
export const ACTION_SET_INSTRUCTORS_LOADING = '[start step] set instructors loading';
export const ACTION_CLEANUP_START_STEP = '[start step] Clean up';

export const setLoadingServices = (loading) => {
  return {
    type: ACTION_SET_SERVICE_LOADING,
    payload: { loading },
  };
};

export const setLoadingInstructors = (loading) => {
  return {
    type: ACTION_SET_INSTRUCTORS_LOADING,
    payload: { loading },
  };
};

export const selectService = (id) => {
  return (dispatch) => {
    // Cleanup previous set states
    dispatch(goToSelectedStep(STEP_CODES.START));
    // Set service
    dispatch(setSelectedService(id));
    // Fetch instructors list
    dispatch(fetchInstructorsList(id));
  };
};

export const setSelectedService = (id) => {
  return {
    type: ACTION_SET_SELECTED_SERVICE,
    payload: { id },
  };
};

export const fetchServiceTypes = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingServices(true));
      const serviceTypesRequest = await getAxios(dispatch).get('get-user-service-types');
      dispatch(setServiceTypes(serviceTypesRequest.data.service_types));
      dispatch(setLoadingServices(false));
    } catch (e) {
      dispatch(setLoadingServices(false));
      console.error(e);
    }
  };
};

export const setServiceTypes = (serviceTypes) => {
  return {
    type: ACTION_SET_SERVICE_TYPES,
    payload: { serviceTypes },
  };
};

export const fetchInstructorsList = (serviceId) => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingInstructors(true));
      //Api call to BE
      const params = { params: { service_type_id: [serviceId] } };
      const instructorsRequest = await getAxios(dispatch).get('get_service_type_providers', params);
      dispatch(setInstructorsList(instructorsRequest.data.providers));
      dispatch(setLoadingInstructors(false));
    } catch (e) {
      console.error(e);
      dispatch(setLoadingInstructors(false));
    }
  };
};

export const setInstructorsList = (list) => {
  return {
    type: ACTION_SET_INSTRUCTORS_LIST,
    payload: { list },
  };
};

export const selectInstructor = (id) => {
  return (dispatch) => {
    dispatch(setSelectedInstructor(id));
  };
};

export const filterInstructor = (id) => {
  return (dispatch) => {
    dispatch(cleanUpForStepBack(STEP_CODES.START));
    dispatch(setFilterInstructor(id));
  };
};

export const setFilterInstructor = (id) => {
  return {
    type: ACTION_SET_FILTERED_INSTRUCTOR,
    payload: { id },
  };
};

export const setSelectedInstructor = (id) => {
  return {
    type: ACTION_SET_SELECTED_INSTRUCTOR,
    payload: { id },
  };
};

export const cleanupStartStep = (fullClean = false) => {
  return {
    type: ACTION_CLEANUP_START_STEP,
    payload: { fullClean },
  };
};

import classes from './Typography.module.scss';
import { isMobile } from 'react-device-detect';
import { useTheme } from '../../../hooks/hooks';

const H = ({ s, display, children }) => {
  const [theme] = useTheme();

  const size = s <= 0 ? 1 : s >= 4 ? 3 : s;
  const cls = [classes.H, classes[`s${size}`], classes[theme]];
  if (isMobile) {
    cls.push(classes.Mobile);
  }
  if (display) {
    cls.push(classes.Display);
  }
  return <span className={cls.join(' ')}>{children}</span>;
};

export default H;

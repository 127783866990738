import {
  ACTION_SET_USER_INFO,
  ACTION_CLEAR_USER_INFO,
  ACTION_SET_LOGGED_IN,
  ACTION_SET_USER_INFO_LOADING,
} from '../actions/user';

const initialState = {
  isLoggedIn: false,
  userInfo: {},
  isUserInfoLoading: false,
  // userInfo: {
  //   firstName: 'Luke',
  //   secondName: 'Skywalker',
  //   role: 'student',
  //   email: 'luke.skywalker@jedi.org',
  //   unitName: 'Tatooine Hight School',
  // },
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_SET_USER_INFO:
      return {
        ...state,
        userInfo: mapUserInfo(action.payload.userInfo),
      };
    case ACTION_SET_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
      };
    case ACTION_CLEAR_USER_INFO:
      return {
        ...state,
        userInfo: {},
      };
    case ACTION_SET_USER_INFO_LOADING:
      return {
        ...state,
        isUserInfoLoading: action.payload.loading,
      };
    default:
      return state;
  }
}

function mapUserInfo(userInfo) {
  const result = {
    firstName: userInfo.first_name,
    secondName: userInfo.last_name,
    unitName: userInfo.service.name,
  };
  delete userInfo.first_name;
  delete userInfo.last_name;
  return {
    ...userInfo,
    ...result,
  };
}

import classes from './ThankYouPage.module.scss';
import { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import LineBreak from '../../components/UI/LineBreak/LineBreak';
import DateAndTimeInfo from '../../components/InfoBlocks/DateAndTimeInfo';
import MeetingTypeInfo from '../../components/InfoBlocks/MeetingTypeInfo';
import DurationInfo from '../../components/InfoBlocks/DurationInfo';
import InstructorInfo from '../../components/InfoBlocks/InstructorInfo';
import UnitInfo from '../../components/InfoBlocks/UnitInfo';
import UserInfo from '../../components/InfoBlocks/UserInfo';
import NotesInfo from '../../components/InfoBlocks/NotesInfo';
import H from '../../components/UI/Typography/H';
import Text from '../../components/UI/Typography/Text';
import { isRegisteringSelector } from '../../store/selectors/finishStep';
import { useSelector } from 'react-redux';
import React from 'react';
import Loader from '../../components/UI/Loader/Loader';
import { useTheme } from '../../hooks/hooks';

const ThankYouPage = () => {
  const isRegistering = useSelector(isRegisteringSelector);
  const [theme, themes] = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container className={classes.ThankYouPage}>
      <Grid container className={classes.ThankYouPageContainer}>
        {isRegistering ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Grid item xs={12} className={classes.Header}>
              <H s={1}>Din bokning är klar</H>
            </Grid>
            <Grid item xs={12} md={6} className={classes.Results}>
              <LineBreak />
              <DateAndTimeInfo />
              <MeetingTypeInfo />
              <DurationInfo />
              <InstructorInfo />
              <UnitInfo />
              <UserInfo />
              <NotesInfo />
            </Grid>
            <Grid item xs={12} md={6} className={classes.Info}>
              <LineBreak />
              <Grid item xs={12}>
                <Text>Du har fått denna information skickad till dig via e-post.</Text>
                {theme !== themes.EXTERNAL ? (
                  <Text>
                    Har du några frågor om din bokning eller frågor kopplade till Snackamedskolan
                    tveka inte att höra av dig till oss på snackamed@academedia.se.
                  </Text>
                ) : (
                  <Text>
                    Har du några frågor om din bokning eller frågor kopplade till Global Education
                    tveka inte att höra av dig till oss på globaleducation@academedia.se.
                  </Text>
                )}

              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default ThankYouPage;

import { useLocation } from 'react-router-dom';
import { themes, USER_ROLES } from '../constants';
import { stepRoutes, routes } from '../router/routes';
import is from 'is_js';
import { useSelector } from 'react-redux';
import { userInfoSelector } from '../store/selectors/user';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useThemeStyle = (classes, className) => {
  const [theme] = useTheme();
  return classes[`${className}_${theme}`];
};

export const useTheme = () => {
  return [process.env.REACT_APP_THEME || 'basic', themes];
};

export const useThemeWaves = () => {
  // return false;
  const [theme, themes] = useTheme();
  const showOnPages = Object.values(stepRoutes);
  const location = useLocation();
  const showOnThisLocation = is.inArray(location.pathname, showOnPages);
  if (showOnThisLocation) {
    return theme === themes.EXTERNAL;
  }
};

export const useThemeBubbles = () => {
  const [theme, themes] = useTheme();
  return !useThemeWaves() && theme === themes.EXTERNAL;
};

export const useIsAdmin = () => {
  const userInfo = useSelector(userInfoSelector);
  return userInfo.role_slug === USER_ROLES.PERSONNEL;
};

export const useContentHeight = () => {
  const location = useLocation();

  function getPositionAtCenter(element) {
    const { top, left, width, height } = element.getBoundingClientRect();
    return {
      x: left + width / 2,
      y: top + height / 2,
    };
  }

  function getDistanceBetweenElements(a, b) {
    const aPosition = getPositionAtCenter(a);
    const bPosition = getPositionAtCenter(b);

    return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y);
  }
  const header = document.getElementsByTagName('header')[0];
  const footer = document.getElementsByTagName('footer')[0];

  if (location.pathname === routes.cancellation && header && footer) {
    return (
      getDistanceBetweenElements(header, footer) - header.offsetHeight / 2 - footer.offsetHeight / 2
    );
  }
  return 0;
};

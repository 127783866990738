import classes from './MobileMenu.module.scss';
import React from 'react';
import MobileMenuPopup from './MobileMenuPopup/MobileMenuPopup';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useThemeStyle } from '../../hooks/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { showMobileMenuSelector } from '../../store/selectors/common';
import { setShowMobileMenu } from '../../store/actions/common';

const MobileMenu = ({ isAdmin }) => {
  const MobileMenuClass = useThemeStyle(classes, 'MobileMenu');
  const showMobileMenu = useSelector(showMobileMenuSelector);
  const dispatch = useDispatch();

  const hideMenu = () => {
    dispatch(setShowMobileMenu(false));
  };

  const cls = [MobileMenuClass];
  if (isAdmin) {
    cls.push(classes.Admin);
  }

  showMobileMenu ? disableBodyScroll(document) : enableBodyScroll(document);

  return (
    <div className={cls.join(' ')}>
      {showMobileMenu ? <MobileMenuPopup hideMenu={hideMenu} isAdmin={isAdmin} /> : null}
    </div>
  );
};

export default MobileMenu;

import classes from './MeetingType.module.scss';
import { Grid } from '@material-ui/core';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LineBreak from '../UI/LineBreak/LineBreak';
import { selectedSlotObjectSelector } from '../../store/selectors/dateAndTimeStep';
import { MEETING_TYPES } from '../../constants';
import { selectedMeetingTypeSelector } from '../../store/selectors/finishStep';
import { selectMeetingType } from '../../store/actions/finishStep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faVideo, faUsers } from '@fortawesome/pro-light-svg-icons';
import { useThemeStyle } from '../../hooks/hooks';
import is from 'is_js';
import H from '../UI/Typography/H';
import Text from '../UI/Typography/Text';

const MeetingType = () => {
  const MeetingTypeClass = useThemeStyle(classes, 'MeetingType');
  const dispatch = useDispatch();
  const { type: slotMeetingType, primary_type: primaryType } = useSelector(
    selectedSlotObjectSelector
  );
  const selectedType = useSelector(selectedMeetingTypeSelector);

  const typesList = enrichMeetingTypes(MEETING_TYPES);

  useEffect(() => {
    if (!selectedType) {
      dispatch(selectMeetingType(primaryType));
    }
  }, [primaryType, selectedType, dispatch]);

  const typeClickHandler = (name) => {
    dispatch(selectMeetingType(name));
  };

  const renderMeetingTypes = () => {
    const typeToWalk = typesList.filter((type) => is.inArray(type.id, slotMeetingType));

    return typeToWalk.map((type) => {
      const selected = selectedType === type.id;
      const cls = [classes.Type];
      if (selected) {
        cls.push(classes.Selected);
      }
      return (
        <div className={cls.join(' ')} key={type.id}>
          <button onClick={!selected ? () => typeClickHandler(type.id) : undefined}>
            <FontAwesomeIcon icon={type.Icon} />
          </button>
          <Text type='button'>{type.name}</Text>
        </div>
      );
    });
  };

  return (
    <Grid container className={MeetingTypeClass}>
      <Grid item xs={12} className={classes.Header}>
        <H s={3}>Hur vill du att vi kontaktar dig?</H>
      </Grid>
      <Grid item xs={12}>
        <LineBreak />
      </Grid>
      <Grid item xs={12} className={classes.TypesList}>
        {renderMeetingTypes()}
      </Grid>
    </Grid>
  );
};

function enrichMeetingTypes(meetingTypes) {
  return [
    {
      Icon: faPhone,
      ...meetingTypes.telephone,
    },
    {
      Icon: faVideo,
      ...meetingTypes.chat,
    },
    {
      Icon: faUsers,
      ...meetingTypes.physical,
    },
  ];
}

export default MeetingType;

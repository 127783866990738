import classes from './Button.module.scss';
import is from 'is_js';
import { useThemeStyle } from '../../../hooks/hooks';
const types = ['primary', 'secondary', 'third'];

const Button = ({ type = 'primary', caption, disabled, onClick }) => {
  const ButtonClass = useThemeStyle(classes, 'Button')
  if (is.not.inArray(type, types)) {
    throw new Error(`There is incorrect prop passed to component. type = ${type}`);
  }
  const cls = [ButtonClass, classes[`Style-${type}`]];

  return (
    <button disabled={disabled} onClick={onClick} className={cls.join(' ')}>
      {caption}
    </button>
  );
};

export default Button;

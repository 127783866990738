import classes from './InfoBlocks.module.scss';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { registerDataSelector } from '../../store/selectors/finishStep';
import H from '../UI/Typography/H';
import Text from '../UI/Typography/Text';

const NotesInfo = () => {
  const registerData = useSelector(registerDataSelector);

  return (
    <>
      {registerData.notes.value.length > 0 ? (
        <Grid item xs={12} className={classes.Notes}>
          <Grid item xs={12}>
            <H s={3}>Notering</H>
          </Grid>
          <Grid item xs={12} className={classes.Info}>
            <Text>{registerData.notes.value}</Text>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default NotesInfo;

import classes from './MobileMenuPopup.module.scss';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faExchange } from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router';
import { routes } from '../../../router/routes';
import { getRemoteSecret } from '../../../localStorage/localStorage';
const { REACT_APP_ADMIN_LOGIN_PAGE: link, REACT_APP_BASE_URL: publicURL } = process.env;

const MobileMenuPopup = ({ hideMenu, isAdmin }) => {
  const history = useHistory();
  const encodeUrl = window.btoa(publicURL);

  const logoutClickHandler = () => {
    hideMenu();
    history.push(routes.logout);
  };
  return (
    <React.Fragment>
      <div className={classes.MobileMenuPopup} onClick={hideMenu}></div>
      <div className={classes.Window}>
        {isAdmin ? (
          <a
            href={`${link}user/login_to_admin_panel?ident=${getRemoteSecret()}&frontend_url=${encodeUrl}`}
            className={classes.Button}
          >
            <div className={classes.Icon}>
              <FontAwesomeIcon icon={faExchange} />
            </div>
            <div className={classes.Text}>Admin-läge</div>
          </a>
        ) : null}

        <div className={classes.Button} onClick={logoutClickHandler}>
          <div className={classes.Icon}>
            <FontAwesomeIcon icon={faSignOut} />
          </div>
          <div className={classes.Text}>Logga ut</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MobileMenuPopup;

import { getAxios } from '../../axios/axiosApi';
import * as cookiesManager from '../../cookies/cookies';
import { setUserInfo, setIsLoggedIn, clearUserInfo, setUserInfoLoading } from './user';
import { history } from '../configureStore';

export const ACTION_SET_THEME = '[common] Set App Theme name';
export const ACTION_LOGIN = '[common] Login';
export const ACTION_LOGOUT = '[common] Logout';
export const ACTION_SET_WELCOME_TEXT = '[common] set welcome text';
export const ACTION_SET_WELCOME_TEXT_LOADING = '[common] set welcome text loading';
export const ACTION_SET_ERROR = '[common] set error';
export const ACTION_SET_SHOW_MOBILE_MENU = '[common] set show mobile menu';

export function setTheme(theme) {
  return {
    type: ACTION_SET_THEME,
    payload: { theme },
  };
}

export function setShowMobileMenu(show) {
  return {
    type: ACTION_SET_SHOW_MOBILE_MENU,
    payload: { show },
  };
}

export function setWelcomeTextLoading(loading) {
  return {
    type: ACTION_SET_WELCOME_TEXT_LOADING,
    payload: { loading },
  };
}

export function setError(isError) {
  return {
    type: ACTION_SET_ERROR,
    payload: { isError },
  };
}

export function login(secret) {
  return async (dispatch) => {
    try {
      const requestParams = { params: { ident: secret } };
      const authRequest = await getAxios().get('grandid-authorization', requestParams);
      if (authRequest.data.token) {
        // User is authorized
        // Save token to cookies
        if (cookiesManager.setAuthCookie(authRequest.data.token)) {
          dispatch(setIsLoggedIn(true));
        } else {
          dispatch(setIsLoggedIn(false));
          throw new Error('Auth cookie was not set');
        }
        // Set isAuthorized
      } else {
        dispatch(setIsLoggedIn(false));
        throw new Error('Auth failed');
      }
    } catch (e) {
      dispatch(setIsLoggedIn(false));
      console.error(e);
    }
  };
}

export function fetchUserData() {
  return async (dispatch) => {
    dispatch(setUserInfoLoading(true));
    const userDataRequest = await getAxios(dispatch).get('logged-student');
    // Request user data
    if (userDataRequest.data) {
      dispatch(setUserInfo(userDataRequest.data.user));
      dispatch(setUserInfoLoading(false));
    } else {
      dispatch(setUserInfoLoading(false));
      throw new Error('User data request failed');
    }
  };
}

export function logout() {
  return (dispatch) => {
    cookiesManager.removeAuthCookie();
    dispatch(setIsLoggedIn(false));
    dispatch(clearUserInfo());
  };
}

export function fetchWelcomeText() {
  return async (dispatch) => {
    try {
      dispatch(setWelcomeTextLoading(true));
      const welcomeText = (await getAxios(dispatch).get('settings/welcome_text')).data.value;
      dispatch(setWelcomeText(welcomeText));
      dispatch(setWelcomeTextLoading(false));
    } catch (e) {
      console.error(e);
      dispatch(setWelcomeTextLoading(false));
    }
  };
}

export function showErrorPage() {
  return () => {
    history.push('/error-page');
  };
}

export function setWelcomeText(text) {
  return {
    type: ACTION_SET_WELCOME_TEXT,
    payload: { text },
  };
}

import { Grid } from '@material-ui/core';
import { useState, useEffect, useRef } from 'react';
import classes from './RegisterForm.module.scss';
import Checkbox from '../../UI/Checkbox/Checkbox';
import PhoneInput from './PhoneInput/PhoneInput';
import EmailInput from './EmailInput/EmailInput';
import Text from '../../UI/Typography/Text';
import { useTheme } from '../../../hooks/hooks';

const RegisterForm = ({ onValidate, onDataChange, defaultEmail, validate, onValidateEnd }) => {
  const [theme, themes] = useTheme();
  const phoneInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const checkboxInputRef = useRef(null);
  const [data, setData] = useState({
    phone: {
      value: '',
      isValid: false,
    },
    email: {
      value: defaultEmail,
      isValid: false,
    },
    terms: {
      value: '',
      isValid: false,
    },
    notes: {
      value: '',
      isValid: true,
    },
  });

  // Syncs validation
  useEffect(() => {
    if (validate) {
      [phoneInputRef, emailInputRef].forEach(({ current }) => {
        current.focus();
        current.blur();
      });
      const isFormValid = Object.keys(data).every((field) => data[field].isValid);
      onValidate(isFormValid);
      onDataChange(data);
    }
    onValidateEnd();
    // Do nothing
  }, [validate, data, onValidate, onDataChange, onValidateEnd]);

  const emailChangeHandler = (email) => {
    setData({
      ...data,
      email,
    });
  };

  const phoneChangeHandler = (phone) => {
    setData({
      ...data,
      phone,
    });
  };

  const termsChangeHandler = (termsState) => {
    setData({
      ...data,
      terms: {
        value: termsState,
        isValid: termsState,
      },
    });
  };

  return (
    <Grid container className={classes.RegisterForm}>
      <Grid item xs={12} className={classes.PhoneInput}>
        <PhoneInput onBlur={phoneChangeHandler} reference={phoneInputRef} />
      </Grid>
      <Grid item xs={12} className={classes.EmailInput}>
        <EmailInput
          onBlur={emailChangeHandler}
          defaultValue={defaultEmail}
          reference={emailInputRef}
        />
      </Grid>
      <Grid item xs={12} className={classes.Terms}>
        <Checkbox onClick={termsChangeHandler} reference={checkboxInputRef} validate={validate} />
        <div>
          {theme !== themes.EXTERNAL ? (
            <Text type='legal'>
              Jag godkänner att Snackamedskolan.se, som är en del av AcadeMedia, sparar och hanterar
              mina personuppgifter i syfte att hantera min bokning samt kommunicera, informera och
              förbättra vårt tjänsteutbud. Läs AcadeMedias personuppgiftspolicy.
            </Text>
          ) : (
            <Text type='legal'>
              Jag godkänner att Globaleducation.se, som är en del av AcadeMedia, sparar och hanterar
              mina personuppgifter i syfte att hantera min bokning samt kommunicera, informera och
              förbättra vårt tjänsteutbud. Läs AcadeMedias personuppgiftspolicy.
            </Text>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default RegisterForm;

import classes from './BookingInfo.module.scss';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/sv';
import LineBreak from '../UI/LineBreak/LineBreak';
import DateAndTimeInfo from '../InfoBlocks/DateAndTimeInfo';
import DurationInfo from '../InfoBlocks/DurationInfo';
import InstructorInfo from '../InfoBlocks/InstructorInfo';
import UnitInfo from '../InfoBlocks/UnitInfo';
import H from '../UI/Typography/H';
moment.locale('sv');

const BookingInfo = () => {
  return (
    <Grid container className={classes.BookingInfo}>
      <Grid item xs={12} className={classes.Header}>
        <H s={2}>Din bokning</H>
      </Grid>
      <Grid item xs={12}>
        <LineBreak />
      </Grid>
      <DateAndTimeInfo />
      <DurationInfo />
      <InstructorInfo />
      <UnitInfo />
    </Grid>
  );
};

export default BookingInfo;

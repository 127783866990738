// import classes from './FinishStep.module.scss';
import { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Steps from '../../components/Steps/Steps';
import { isMobile } from 'react-device-detect';
import Welcome from '../../components/Welcome/Welcome';
import StepsContainer from '../StepsContainer/StepsContainer';
import BookingInfo from '../../components/BookingInfo/BookingInfo';
import MeetingType from '../../components/MeetingType/MeetingType';
import RegisterDetails from '../../components/RegisterDetails/RegisterDetails';

const FinishStep = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Steps />
      {isMobile ? null : <Welcome />}

      <StepsContainer>
        <Grid item xs={12} md={6} lg={4} style={{ zIndex: 1 }}>
          <BookingInfo />
        </Grid>
        <Grid item xs={12} md={6} lg={4} style={{ zIndex: 1 }}>
          <MeetingType />
        </Grid>
        <Grid item xs={12} md={6} lg={4} style={{ zIndex: 1 }}>
          <RegisterDetails />
        </Grid>
      </StepsContainer>
    </div>
  );
};

export default FinishStep;

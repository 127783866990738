import classes from './CancellationPage.module.scss';
import Content from '../../layout/Content/Content';
import Footer from '../../layout/Footer/Footer';
import Header from '../../layout/Header/Header';
import { Grid } from '@material-ui/core';
import H from '../UI/Typography/H';
import LineBreak from '../UI/LineBreak/LineBreak';
import Button from '../UI/Button/Button';
import { useHistory } from 'react-router';
import { useThemeStyle } from '../../hooks/hooks';

const CancellationPage = () => {
  const CancellationPageClass = useThemeStyle(classes, 'CancellationPage');
  const history = useHistory();

  const buttonClickHandler = () => {
    history.push('/');
  };

  return (
    <div className={CancellationPageClass}>
      <Header noActions />
      <Content>
        <div className={classes.PageContent}>
          <Grid container className={classes.Container}>
            <Grid item xs={12} className={classes.Heading}>
              <H s={1}>Din bokade tid är nu avbokad</H>
            </Grid>
            <Grid item xs={12}>
              <LineBreak />
            </Grid>
            <Grid item xs={12} className={classes.Button}>
              <Button caption='Boka en ny tid' onClick={buttonClickHandler} />
            </Grid>
          </Grid>
        </div>
      </Content>
      <Footer />
    </div>
  );
};

export default CancellationPage;

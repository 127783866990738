import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

const CrossInvalidIcon = () => {
  return (
    <span
      style={{
        fontSize: 18,
        color: '#E13600'
      }}
    >
      <FontAwesomeIcon icon={faTimesCircle} />
    </span>
  );
};

export default CrossInvalidIcon;

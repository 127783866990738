import classes from './LoginAdminButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchange } from '@fortawesome/pro-light-svg-icons';
import { getRemoteSecret } from '../../localStorage/localStorage';
import { useThemeStyle } from '../../hooks/hooks';
const { REACT_APP_ADMIN_LOGIN_PAGE: link, REACT_APP_BASE_URL: publicURL } = process.env;

const LoginAdminButton = () => {
  const LoginAdminButtonClass = useThemeStyle(classes, 'LoginAdminButton');
  const encodeUrl = window.btoa(publicURL);
  return (
    <a
      href={`${link}user/login_to_admin_panel?ident=${getRemoteSecret()}&frontend_url=${encodeUrl}`}
      className={LoginAdminButtonClass}
    >
      <span>
        <FontAwesomeIcon icon={faExchange} />
      </span>
      <span>Admin-läge</span>
    </a>
  );
};

export default LoginAdminButton;

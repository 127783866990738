import classes from './CalendarSlots.module.scss';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  slotsSelector,
  selectedSlotSelector,
  slotsLoadingSelector,
} from '../../store/selectors/dateAndTimeStep';
import moment from 'moment';
import 'moment/locale/sv';
import { selectSlot, openPopup } from '../../store/actions/dateAndTimeStep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUserCheck } from '@fortawesome/pro-light-svg-icons';
import Loader from '../UI/Loader/Loader';
import { userInfoSelector } from '../../store/selectors/user';
import { useThemeStyle } from '../../hooks/hooks';

moment.locale('sv');

const CalendarSlots = ({ disabled }) => {
  const CalendarSlotsClass = useThemeStyle(classes, 'CalendarSlots');
  const dispatch = useDispatch();
  const slots = useSelector(slotsSelector);
  const slotsLoading = useSelector(slotsLoadingSelector);
  const selectedSlot = useSelector(selectedSlotSelector);
  const userId = useSelector(userInfoSelector).id;

  const slotClickHandler = (slot) => {
    dispatch(selectSlot(slot.id));
  };

  const renderRealSlots = () => {
    const resultsSet = [];
    const grouped = groupSlots(slots);
    grouped.forEach((slotGroup, time) => {
      const cls = [classes.Slot];
      const isMulti = slotGroup.length > 1;
      const selected = slotGroup.reduce((acc, s) => acc || s.id === selectedSlot, false);
      if (selected) {
        cls.push(classes.Selected);
      }
      let btn;

      if (isMulti) {
        const popUpData = slotGroup.map((slot) => ({
          isAvailable: isAvailable(slot, userId),
          slot,
        }));
        const isSlotAvailable = popUpData.filter((i) => i.isAvailable).length > 0;
        btn = (
          <button
            className={classes.MultiBtn}
            onClick={() => dispatch(openPopup(popUpData))}
            disabled={!isSlotAvailable}
          >
            <span className={classes.Icon}>
              <FontAwesomeIcon icon={selected ? faUserCheck : faUserPlus} />
            </span>
            <span className={classes.Time}>{time}</span>
          </button>
        );
      } else {
        const slot = slotGroup[0];
        btn = (
          <button
            onClick={!selected ? () => slotClickHandler(slot) : undefined}
            disabled={!isAvailable(slot, userId)}
          >
            <span className={classes.Time}>{time}</span>
          </button>
        );
      }

      resultsSet.push(
        <Grid item xs={6} md={3} className={cls.join(' ')} key={time}>
          {btn}
        </Grid>
      );
    });

    return resultsSet;
  };

  const renderDummySlots = () => {
    return generateDummyRange().map((time, i) => {
      return (
        <Grid item xs={4} md={3} className={classes.Slot} key={i}>
          <button disabled={true}>
            <span>{time}</span>
          </button>
        </Grid>
      );
    });
  };

  const cls = [CalendarSlotsClass];
  if (disabled) {
    cls.push(classes.Blocked);
  }

  return (
    <Grid container className={cls.join(' ')}>
      {disabled ? renderDummySlots() : slotsLoading ? <Loader /> : renderRealSlots()}
    </Grid>
  );
};

function groupSlots(slots) {
  const grouped = new Map();

  for (let slot of slots) {
    const timeLabel = moment(slot.start).format('HH.mm');
    const existedGroup = grouped.get(timeLabel);
    if (existedGroup) {
      grouped.set(timeLabel, [...existedGroup, slot]);
    } else {
      grouped.set(timeLabel, [slot]);
    }
  }

  return new Map([...grouped.entries()].sort());
}

function generateDummyRange() {
  const range = ['08.00'];
  const startHour = 8;
  let minToAdd = '30';
  for (let i = 0; i < 19; i++) {
    const hr = Math.ceil(i / 2) + startHour;
    const time = `${hr < 10 ? '0' : ''}${hr}.${minToAdd}`;
    minToAdd = minToAdd === '30' ? '00' : '30';
    range.push(time);
  }
  return range;
}

function isAvailable(slot, userId) {
  const isOwnMeeting = Number(userId) === Number(slot.providerId);
  const { start, count_assigned_users: countAssignedUsers, max_members: maxMembers } = slot;
  const hasFreeSpot = countAssignedUsers < Number(maxMembers);
  const now = moment();
  const meetingTime = moment(start);
  const isBefore = now.add(30, 'minutes').unix() - meetingTime.unix() > 0;

  return (
    !Boolean(slot.is_booked) && !isBefore && hasFreeSpot && !isOwnMeeting
  );
}

export default CalendarSlots;

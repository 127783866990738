import classes from './Checkbox.module.scss';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useThemeStyle } from '../../../hooks/hooks';

const Checkbox = ({ onClick, validate }) => {
  const CheckboxClass = useThemeStyle(classes, 'Checkbox');
  const [isChecked, setIsChecked] = useState(false);
  const [cls, setCls] = useState([CheckboxClass]);
  const btnClickHandler = () => {
    const newState = !isChecked;
    setIsChecked(newState);
    if (onClick) {
      onClick(newState);
    }
  };

  useEffect(() => {
    if (!isChecked && validate) {
      setCls(() => [...cls, classes.NotValid]);
    }
  }, [validate, isChecked, cls]);

  useEffect(() => {
    if (isChecked) {
      setCls(() => [CheckboxClass]);
    }
  }, [isChecked, setCls, CheckboxClass]);

  return (
    <span className={cls.join(' ')}>
      <button onClick={btnClickHandler}>
        {isChecked ? <FontAwesomeIcon icon={faCheck} /> : null}
      </button>
    </span>
  );
};

export default Checkbox;

import classes from './Instructors.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import LineBreak from '../UI/LineBreak/LineBreak';
import {
  instructorsSelector,
  filteredInstructorSelector,
  isInstructorsLoadingSelector,
} from '../../store/selectors/startStep';
import { filterInstructor } from '../../store/actions/startStep';
import { ALL_INSTRUCTORS_OBJ as allInstructors } from '../../constants';
import H from '../UI/Typography/H';
import Text from '../UI/Typography/Text';
import Button from '../UI/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserPlus } from '@fortawesome/pro-light-svg-icons';
import RoundButton from '../UI/RoundButton/RoundButton';
import Loader from '../UI/Loader/Loader';

const Instructors = () => {
  const isInstructorsLoading = useSelector(isInstructorsLoadingSelector);
  const [mobileSelectedInstructor, setMobileSelectedInstructor] = useState(null);
  const dispatch = useDispatch();
  const instructors = useSelector(instructorsSelector);
  const filteredInstructor = useSelector(filteredInstructorSelector);

  const instructorClickHandler = (id) => {
    dispatch(filterInstructor(id));
  };

  const mobileInstructorClickHandler = (id) => {
    setMobileSelectedInstructor(id);
  };

  const renderInstructors = () => {
    const clickHandler = isMobile ? mobileInstructorClickHandler : instructorClickHandler;
    const selectedInstructorToCompare = isMobile ? mobileSelectedInstructor : filteredInstructor;
    const listToMap =
      instructors && instructors.length <= 1 ? instructors : [...instructors, allInstructors];
    return listToMap.map((instructor) => {
      const selected = selectedInstructorToCompare === instructor.id;
      const cls = [classes.Instructor];

      return (
        <Grid item xs={12} md={6} lg={4} key={instructor.id}>
          <div className={cls.join(' ')}>
            <RoundButton
              selected={selected}
              onClick={!selected ? () => clickHandler(instructor.id) : undefined}
            >
              {instructor.id === 0 ? (
                <FontAwesomeIcon icon={faUserPlus} />
              ) : (
                <FontAwesomeIcon icon={faUser} />
              )}
            </RoundButton>
            <span onClick={!selected ? () => clickHandler(instructor.id) : undefined}>
              <Text type='button'>{instructor.firstName} {instructor.lastName}</Text>
            </span>
          </div>
        </Grid>
      );
    });
  };
  return (
    <Grid item xs={12} className={classes.Instructors}>
      <Grid container>
        <Grid item xs={12} className={classes.Header}>
          <H s={3}>Välj person</H>
        </Grid>
        <Grid item xs={12}>
          <LineBreak />
        </Grid>
        <Grid container>{isInstructorsLoading ? <Loader /> : renderInstructors()}</Grid>
        {isMobile ? (
          <Grid item xs={12} className={classes.MobileNextStepBtn}>
            <Button
              caption='Gå vidare och välj tid'
              disabled={mobileSelectedInstructor === null}
              onClick={() => instructorClickHandler(mobileSelectedInstructor)}
            />
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default Instructors;

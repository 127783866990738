import { SESSION_COOKIE_NAME } from '../constants';
import cookies from 'react-cookies';

export function setAuthCookie(token) {
  try {
    cookies.save(SESSION_COOKIE_NAME, token, {
      maxAge: 60 * 60,
      sameSite: true,
    });
    return true;
  } catch (e) {
    return false;
  }
}

export function removeAuthCookie() {
  cookies.remove(SESSION_COOKIE_NAME);
}

export function removeAllCookies() {
  Object.keys(cookies.loadAll(true)).map((key) => cookies.remove(key));
}

export function getAuthCookie() {
  return cookies.load(SESSION_COOKIE_NAME) || false;
}

export function isLoggedIn() {
  return Boolean(getAuthCookie());
}

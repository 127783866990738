import classes from './PhoneInput.module.scss';
import React from 'react';
import { useState } from 'react';
import CrossInvalidIcon from '../../../UI/Icons/CrossInvalidIcon';
import CheckValidIcon from '../../../UI/Icons/CheckValidIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAndroid } from '@fortawesome/pro-light-svg-icons';
import { useThemeStyle } from '../../../../hooks/hooks';

const PhoneInput = ({ onBlur, reference }) => {
  const PhoneInputClass = useThemeStyle(classes, 'PhoneInput');
  const [phoneInputState, setPhoneInputState] = useState({
    viewValue: '',
    value: '',
  });
  const [isValid, setIsValid] = useState(null);
  const [error, setError] = useState(null);

  const phoneChangeHandler = (e) => {
    let input = e.target.value;
    const isValidInput = isNumeric(input.slice(-1));
    const { viewValue } = phoneInputState;

    if (isValidInput || viewValue.length > input.length) {
      if (input.length === 4) {
        if (viewValue.length !== 5) {
          input += '-';
        }
      }
      if (input.length === 8) {
        if (viewValue.length !== 9) {
          input += ' ';
        }
      }
      const numPhone = input
        .split('')
        .map((n) => (isNumeric(n) ? n : ''))
        .join('');
      setPhoneInputState({ viewValue: input, value: numPhone });
    }
  };

  const transformInputData = () => {
    let input = phoneInputState.value;
    if (input.length >= 4) {
      input = input
        .split('')
        .map((c, i) => (i === 3 ? c + '-' : c))
        .join('');
    }
    if (input.length >= 8) {
      input = input
        .split('')
        .map((c, i) => (i === 7 ? c + ' ' : c))
        .join('');
    }

    setPhoneInputState({
      ...phoneInputState,
      viewValue: input,
    });
  };

  const blurHandler = () => {
    transformInputData();
    validate();
  };

  const validate = () => {
    //Validate required
    let valid = phoneInputState.value.length > 0;
    setIsValid(valid);

    if (!valid) {
      setError('Giltigt telefonnummer krävs');
    }

    //Validate min length
    if (valid) {
      valid = phoneInputState.value.length >= 7;
      if (!valid) {
        setError('Phone number minimum length is 7 digits');
      }
    }

    if (valid) {
      valid = phoneInputState.value.length <= 13;
      if (!valid) {
        setError('Phone number maximum length is 13 digits');
      }
    }

    setIsValid(valid);

    if (onBlur) {
      onBlur({
        ...phoneInputState,
        isValid: valid,
      });
    }
  };

  const iconCls = [];
  const cls = [PhoneInputClass];
  if (isValid === false) {
    cls.push(classes.Invalid);
  }
  if (phoneInputState.value.length <= 0) {
    iconCls.push(classes.Empty);
  }

  return (
    <div className={cls.join(' ')}>
      <i className={iconCls.join(' ')}>
        <FontAwesomeIcon icon={faMobileAndroid} />
      </i>
      {isValid === false ? (
        <span className={classes.ValidationIcon}>
          <CrossInvalidIcon />
        </span>
      ) : isValid === true ? (
        <span className={classes.ValidationIcon}>
          <CheckValidIcon />
        </span>
      ) : null}
      <input
        placeholder='Ange telefonnummer'
        type='text'
        onChange={phoneChangeHandler}
        onBlur={blurHandler}
        value={phoneInputState.viewValue}
        ref={reference}
      />
      {isValid === false ? (
        <div className={classes.ErrorMessage}>
          <p>{error}</p>
        </div>
      ) : null}
    </div>
  );
};

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

export default PhoneInput;

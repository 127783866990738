import {
  ACTION_CLEANUP_START_STEP,
  ACTION_SET_FILTERED_INSTRUCTOR,
  ACTION_SET_INSTRUCTORS_LIST,
  ACTION_SET_INSTRUCTORS_LOADING,
  ACTION_SET_SELECTED_INSTRUCTOR,
  ACTION_SET_SELECTED_SERVICE,
  ACTION_SET_SERVICE_LOADING,
  ACTION_SET_SERVICE_TYPES,
} from '../actions/startStep';

const initialState = {
  isServiceLoading: false,
  isInstructorsLoading: false,
  serviceTypes: [],
  instructors: [],
  selectedService: null,
  selectedInstructor: null, // Instructor id for selected slot | could not be 'ALL'
  filteredInstructor: null, // Instructor id to filter view | could be 'ALL'
};

export default function startStepReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_SET_SERVICE_LOADING:
      return {
        ...state,
        isServiceLoading: action.payload.loading,
      };
    case ACTION_SET_INSTRUCTORS_LOADING:
      return {
        ...state,
        isInstructorsLoading: action.payload.loading,
      };
    case ACTION_SET_SELECTED_SERVICE:
      return {
        ...state,
        selectedService: Number(action.payload.id),
      };
    case ACTION_SET_SELECTED_INSTRUCTOR:
      return {
        ...state,
        selectedInstructor: Number(action.payload.id),
      };
    case ACTION_CLEANUP_START_STEP:
      return {
        ...state,
        selectedInstructor: null,
        filteredInstructor: null,
        selectedService: action.payload.fullClean ? null : state.selectedService,
      };
    case ACTION_SET_INSTRUCTORS_LIST:
      return {
        ...state,
        instructors: mapInstructors(action.payload.list),
      };
    case ACTION_SET_FILTERED_INSTRUCTOR:
      return {
        ...state,
        filteredInstructor: Number(action.payload.id),
      };
    case ACTION_SET_SERVICE_TYPES:
      return {
        ...state,
        serviceTypes: mapServiceTypes(action.payload.serviceTypes),
      };
    default:
      return state;
  }
}

function mapInstructors(instructors) {
  return instructors.map((instructor) => {
    const { first_name: firstName, last_name: lastName, id } = instructor;
    const mappedInstructor = {
      firstName,
      lastName,
      id: Number(id),
    };
    delete instructor.first_name;
    delete instructor.last_name;
    delete instructor.id;
    return { ...mappedInstructor, ...instructor };
  });
}

function mapServiceTypes(serviceTypes) {
  return serviceTypes.map((service) => {
    return { ...service, id: Number(service.id) };
  });
}

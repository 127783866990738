// import classes from './StartStep.module.scss';
// import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { goToNextStep, setCurrentStep } from '../../store/actions/steps';
import { isMobile } from 'react-device-detect';
// import { hasNextStepSelector } from '../../store/selectors/steps';
import Steps from '../../components/Steps/Steps';
import Welcome from '../../components/Welcome/Welcome';
import ServiceTypes from '../../components/ServiceTypes/ServiceTypes';
import StepsContainer from '../StepsContainer/StepsContainer';
import Instructors from '../../components/Instructors/Instructors';
import DateAndTime from '../../components/DateAndTime/DateAndTime';
import {
  selectedServiceSelector,
  filteredInstructorSelector,
} from '../../store/selectors/startStep';

const StartStep = () => {
  const dispatch = useDispatch();
  const filteredInstructor = useSelector(filteredInstructorSelector);
  const selectedService = useSelector(selectedServiceSelector);

  useEffect(() => {
    if (filteredInstructor !== null) {
      dispatch(goToNextStep());
    }
  }, [filteredInstructor, dispatch]);

  useEffect(() => {
    dispatch(setCurrentStep('start'));
  }, [dispatch]);

  return (
    <div>
      <Steps />
      <Welcome />
      <StepsContainer>
        <Grid item xs={12} md={6} style={{ zIndex: 1 }}>
          <ServiceTypes />
          {selectedService ? <Instructors /> : null}
        </Grid>
        {!isMobile ? (
          <Grid item xs={12} md={6}>
            <DateAndTime disabled />
          </Grid>
        ) : null}
      </StepsContainer>
    </div>
  );
};

export default StartStep;

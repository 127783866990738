import { STEP_STATUS, STEP_CODES } from '../../constants';
import { stepRoutes } from '../../router/routes';
import { history } from '../configureStore';
import { cleanupStartStep } from './startStep';
import { cleanupDateAndTimeStep } from './dateAndTimeStep';
import { cleanupFinishStep } from './finishStep';

export const ACTION_SET_STEPS_LIST = '[Steps] Set steps list';
export const ACTION_SET_HAS_NEXT_STEP = '[Steps] Set has next step';
export const ACTION_SET_CURRENT_STEP = '[Steps] Set current step';

export const goToNextStep = () => {
  return (dispatch, getState) => {
    const { list, sequence } = getState().steps;
    const newList = [...list];
    const currentStepIndex = findCurrentStepIndex(list, sequence);
    const nextStepIndex = currentStepIndex + 1;
    if (nextStepIndex > sequence.length) {
      throw new Error('There is no next step!');
    }
    if (nextStepIndex + 1 === sequence.length) {
      dispatch(setHasNextStep(false));
    }
    newList[currentStepIndex].status = STEP_STATUS.PASSED;
    newList[nextStepIndex].status = STEP_STATUS.ACTIVE;
    dispatch(setStepsList(newList));
    history.push(defineStepRoute(newList[nextStepIndex].code));
  };
};

export const goToSelectedStep = (nextStepCode) => {
  return (dispatch, getState) => {
    const { list, sequence } = getState().steps;
    let newList = [...list];
    const nextStepIndex = findStepCodeIndex(sequence, nextStepCode);

    let after = newList.slice(nextStepIndex + 1);
    const newStepItem = newList[nextStepIndex];
    // Set active clicked item
    newStepItem.status = STEP_STATUS.ACTIVE;
    // Set pending everything after clicked item
    after = after.map((step) => {
      step.status = STEP_STATUS.PENDING;
      return step;
    });

    newList = newList.slice(0, nextStepIndex);
    newList.push(newStepItem);
    newList = [...newList, ...after];

    dispatch(setStepsList(newList));
    dispatch(setHasNextStep(true));
    dispatch(cleanUpForStepBack(nextStepCode));
    history.push(defineStepRoute(newList[nextStepIndex].code));
  };
};

export const setStepsList = (list) => {
  return {
    type: ACTION_SET_STEPS_LIST,
    payload: { list },
  };
};

export const setHasNextStep = (has) => {
  return {
    type: ACTION_SET_HAS_NEXT_STEP,
    payload: { has },
  };
};

export const cleanUpForStepBack = (stepCode) => {
  return (dispatch) => {
    if (stepCode === 0) {
      dispatch(cleanupStartStep(true));
      dispatch(cleanupDateAndTimeStep());
      dispatch(cleanupFinishStep());
    }
    if (stepCode === STEP_CODES.START) {
      // cleanup date and time, cleanup finish, cleanup start
      dispatch(cleanupStartStep());
      dispatch(cleanupDateAndTimeStep());
      dispatch(cleanupFinishStep());
    }
    if (stepCode === STEP_CODES.DATE_AND_TIME) {
      // cleanup date and time, cleanup finish
      dispatch(cleanupDateAndTimeStep());
      dispatch(cleanupFinishStep());
    }
  };
};

export const setCurrentStep = (step) => {
  return {
    type: ACTION_SET_CURRENT_STEP,
    payload: { step }
  }
}

// -----------------------Functions------------------------//

function findCurrentStepIndex(list, sequence) {
  const code = list.find((item) => item.status === STEP_STATUS.ACTIVE).code;
  return sequence.indexOf(code);
}

function findStepCodeIndex(sequence, code) {
  return sequence.indexOf(code);
}

function defineStepRoute(code) {
  return mapCodeToRoute()[code];
}

function mapCodeToRoute() {
  return {
    [STEP_CODES.START]: stepRoutes.start,
    [STEP_CODES.DATE_AND_TIME]: stepRoutes.dateAndTime,
    [STEP_CODES.FINISH]: stepRoutes.finish,
  };
}

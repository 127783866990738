import { useHistory } from 'react-router';
import classes from './LogoutButton.module.scss';
import { routes } from '../../router/routes';
import { useThemeStyle } from '../../hooks/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-light-svg-icons';

const LogoutButton = () => {
  const history = useHistory();
  const LogoutButtonClass = useThemeStyle(classes, 'LogoutButton');

  const clickHandler = () => {
    history.push(routes.logout);
  };

  return (
    <div className={LogoutButtonClass}>
      <FontAwesomeIcon icon={faSignOut} />
      <button onClick={clickHandler}>Logga ut</button>
    </div>
  );
};

export default LogoutButton;

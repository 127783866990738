import React from 'react';
import classes from './Content.module.scss';
import { useContentHeight, useThemeBubbles } from '../../hooks/hooks';
import BubbleMedium from '../Bubbles/BubbleMedium';
import BubbleLarge from '../Bubbles/BubbleLarge';
import BubbleSmall from '../Bubbles/BubbleSmall';
import { isMobile } from 'react-device-detect';

const Content = ({ children }) => {
  const showBubbles = useThemeBubbles();
  const contentHeight = useContentHeight();

  const contentStyle = {};
  if (contentHeight) {
    contentStyle.minHeight = contentHeight;
  }

  return (
    <div className={classes.Content} style={contentStyle}>
      <main>
        {showBubbles ? (
          <React.Fragment>
            {!isMobile ? (
              <React.Fragment>
                <BubbleSmall />
                <BubbleLarge />
              </React.Fragment>
            ) : null}
            <BubbleMedium />
          </React.Fragment>
        ) : null}
        {children}
      </main>
    </div>
  );
};

export default Content;
